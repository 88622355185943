<script lang="ts" setup>
import { useClientSourceQuery } from "@/query/useClientSourceQuery";
import { ref } from "vue";
import AppButton from "@/components/AppButton.vue";
import SettingsClientSourceCreateModal from "@/modules/settings/components/SettingsClientSourceCreateModal.vue";
import SettingsClientSourceRemoveModal from "@/modules/settings/components/SettingsClientSourceRemoveModal.vue";
import IconPencil from '@/components/Icons/IconPencil.vue';
import IconTrash from '@/components/Icons/IconTrash.vue';
import AppIcon from '@/components/AppIcon.vue';
import { plural } from "@/use/useFormat";

const { data: clientSources } = useClientSourceQuery();

const clientSourceModalShow = ref(false);
const editableClientSourceId = ref(null);
const clientSourceRemoveModalShow = ref(false);

const clientSourceModalOpen = (clientSourceId?: string) => {
	editableClientSourceId.value = clientSourceId;
	clientSourceModalShow.value = true;
};

const clientSourceModalClose = () => {
	clientSourceModalShow.value = false;
};

const clientSourceRemoveModalOpen = (clientSourceId: string) => {
	editableClientSourceId.value = clientSourceId;
	clientSourceRemoveModalShow.value = true;
};

const clientSourceRemoveModalClose = () => {
	clientSourceRemoveModalShow.value = false;
};
</script>

<template>
	<div class="flex flex-col gap-y-6">
		<div class="flex flex-col gap-y-[32px]">
			<div class="flex flex-col bg-white max-w-[400px]">
				<div class="py-[10px] px-[8px]">Источники клиентов</div>

				<div class="flex flex-col">
					<div v-for="clientSource in clientSources" :key="clientSource.id">
						<div class="flex justify-between items-center text-gray-primary py-[10px] px-[8px] bg-white">
							<div class="flex gap-x-[23px] items-center grow">
								<div>{{ clientSource.title }}</div>
								<div class="text-sm text-right grow">
									{{ clientSource.clients_count }}
									{{ plural(clientSource.clients_count, ['клиент', 'клиента', 'клиентов']) }}
								</div>
							</div>
							<div class="flex gap-x-[17px]">
								<div></div>
								<div class="flex gap-x-[12px] items-center">
									<AppIcon :width="17" :height="20" :icon="IconPencil"
										class="cursor-pointer hover:text-violet-primary"
										@click="clientSourceModalOpen(clientSource.id)" />
									<div class="w-[1px] h-[16px] bg-gray-secondary"></div>
									<AppIcon :width="17" :height="20" :icon="IconTrash"
										class="cursor-pointer hover:text-red-notify"
										@click="clientSourceRemoveModalOpen(clientSource.id)" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div>
			<AppButton @click="clientSourceModalOpen()">Добавить новый источник</AppButton>
		</div>
	</div>

	<SettingsClientSourceCreateModal v-if="clientSourceModalShow" class="md:w-[894px]"
		:clientSourceId="editableClientSourceId" @close="clientSourceModalClose()" />

	<SettingsClientSourceRemoveModal v-if="clientSourceRemoveModalShow" :clientSourceId="editableClientSourceId"
		@close="clientSourceRemoveModalClose()" />
</template>
