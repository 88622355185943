<script setup>
import { tabs } from '@/modules/settings/settings-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { computed, ref } from 'vue';
import AppIcon from '@/components/AppIcon.vue';
import IconLock from '@/components/Icons/IconLock.vue';
import AppInput from '@/components/AppInput.vue';
import { FieldTypes, getLabel } from '@/enums/FieldTypes';
import AppSelect from '@/components/AppSelect.vue';
import AppCheckbox from '@/components/AppCheckbox.vue';
import AppButton from '@/components/AppButton.vue';
import { useCustomFieldsQuery } from '@/query/useCustomFieldQuery';
import SettingsBookingCustomFieldsModal from '@/modules/settings/components/SettingsBookingCustomFieldsModal.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';

const isLoading = ref(false);

const { data: customFields } = useCustomFieldsQuery({}, { select: (response) => response?.data?.data });

const systemFields = [
  {
    id: '1',
    field_name: 'name',
    field_label: 'Имя',
    field_type: 'text',
    is_system: true,
    is_required: true,
  },
  {
    id: '2',
    field_name: 'phone',
    field_label: 'Телефон',
    field_type: 'phone',
    is_system: true,
    is_required: true,
  },
];

const fields = computed(() => {
  if (!customFields.value) {
    return systemFields;
  }

  return systemFields.concat(customFields.value);
});

const fieldTypes = computed(() => {
  return Object.values(FieldTypes).map((type) => {
    return {
      label: getLabel(type),
      value: type,
    };
  });
});

const customFieldsModalShow = ref(false);
const editableFieldId = ref(null);

const editField = (fieldId) => {
  editableFieldId.value = fieldId;
  customFieldsModalShow.value = true;
};

const closeModal = () => {
  editableFieldId.value = null;
  customFieldsModalShow.value = false;
};
</script>

<template>
  <CabinetLayout
    :tabs="tabs"
    :loading="isLoading"
    :hide-in-loading="true"
  >
    {{ fields }}
    <table class="max-w-[800px] table-fixed">
      <tr class="text-sm text-gray-primary">
        <td class="w-[20px]"></td>
        <td class="w-[30%]">Название поля</td>
        <td class="w-[30%]">Тип</td>
        <td class="w-[30%]">Обязательное поле</td>
      </tr>
      <tr
        v-for="field in fields"
        :key="field.id"
      >
        <td class="row">
          <AppIcon
            v-if="field.system"
            :icon="IconLock"
            :width="16"
            :height="16"
          />
        </td>
        <td class="row">
          <AppInput
            :model-value="field.field_label"
            :disabled="field.is_system"
          />
        </td>
        <td class="row">
          <AppSelect
            :options="fieldTypes"
            disabled
            :model-value="field.field_type"
          />
        </td>
        <td class="row pl-[20px]">
          <AppCheckbox
            :model-value="!!field.is_required"
            :disabled="field.is_system"
          />
        </td>
        <td>
          <AppIcon
            :icon="IconPencil"
            @click="editField(field.id)"
            class="w-[18px] h-[18px] cursor-pointer hover:text-violet-primary"
          />
        </td>
      </tr>
      <tr>
        <td
          colspan="4"
          class="text-center py-[10px]"
        ></td>
      </tr>
    </table>

    <div class="mt-[10px]">
      <AppButton
        outline
        @click="customFieldsModalShow = true"
        >Добавить поле</AppButton
      >
    </div>

    <SettingsBookingCustomFieldsModal
      v-if="customFieldsModalShow"
      class="md:w-[894px]"
      :field-id="editableFieldId"
      @closed="closeModal()"
      @saved="closeModal()"
    />
  </CabinetLayout>
</template>

<style scoped>
.row {
  @apply border-b border-[#F3F3F3] py-[13px] px-[10px] box-content;
}
</style>
