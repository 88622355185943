<script setup>
import { computed, ref, unref } from 'vue';
import AppInput from '@/components/AppInput.vue';
import { useAuthStore } from '@/store/AuthStore';
import AppButton from '@/components/AppButton.vue';
import AppSelect from '@/components/AppSelect.vue';
import { updateBusiness } from '@/api/business';
import { DateTime } from 'luxon';
import { useToast } from '@/use/useToast.js';
import { tabs } from '@/modules/settings/settings-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import timezones from '../components/constants/timezones.json';
import SettingsMainAddress from '@/modules/settings/components/SettingsMainAddress.vue';
import SettingsBusinessPhoto from '@/modules/settings/components/SettingsBusinessPhoto.vue';

const { hasAccess, fetchAuth } = useAuthStore();

const business = computed(() => useAuthStore().currentBusiness);

const title = ref(business.value.title);
const latitude = ref(business.value.latitude);
const longitude = ref(business.value.longitude);
const timezone = ref(business.value?.timezone);
const loading = ref(false);
const openTime = ref(business.value.openTime);
const closeTime = ref(business.value.closeTime);
const phone = ref(business.value.phone);

const timezonesOptions = computed(() => {
  return timezones.map((timezone) => {
    return {
      title: `+${timezone.offset} ${timezone.title}`,
      value: timezone.offset,
    };
  });
});

const submit = async () => {
  loading.value = true;

  try {
    const params = {
      ...business.value,
      ...{
        title: title.value,
        latitude: latitude.value,
        longitude: longitude.value,
        timezone: timezone.value,
        openTime: openTime.value,
        closeTime: closeTime.value,
        phone: unref(phone) ?? 0,
      },
    };

    await updateBusiness(params);
    await fetchAuth();
    useToast().show('Изменения сохранены');
  } finally {
    loading.value = false;
  }
};

const decreaseOpenTime = () => {
  openTime.value = DateTime.fromISO(openTime.value).minus({ minutes: 30 }).toFormat('HH:mm');
};

const increaseOpenTime = () => {
  openTime.value = DateTime.fromISO(openTime.value).plus({ minutes: 30 }).toFormat('HH:mm');
};

const increaseCloseTime = () => {
  closeTime.value = DateTime.fromISO(closeTime.value).plus({ minutes: 30 }).toFormat('HH:mm');
};

const decreaseCloseTime = () => {
  closeTime.value = DateTime.fromISO(closeTime.value).minus({ minutes: 30 }).toFormat('HH:mm');
};

const updateCoordinates = (coordinates) => {
  latitude.value = coordinates[0];
  longitude.value = coordinates[1];
};
</script>

<template>
  <CabinetLayout :tabs="tabs">
    <div class="flex flex-col gap-y-[32px] w-[603px]">
      <div class="flex flex-col gap-y-[24px]">
        <div class="flex flex-col gap-y-[12px]">
          <div>Название</div>
          <div>
            <AppInput v-model="title" />
          </div>
        </div>

        <div>
          <SettingsBusinessPhoto :business="business" />
        </div>

        <div class="flex flex-col gap-y-[12px]">
          <div>Адрес и расположение</div>
          <div>
            <SettingsMainAddress
              :title="title"
              :coordinates="latitude && longitude ? [latitude, longitude] : []"
              @coordinates-changed="updateCoordinates"
            />
          </div>
        </div>

        <div class="flex flex-col gap-y-[12px]">
          <div>Часовой пояс</div>
          <div>
            <AppSelect v-model="timezone" :options="timezonesOptions" option-label="title" option-value="value" />
          </div>
        </div>

        <div class="flex flex-col gap-y-[12px]">
          <div class="flex justify-between">
            <div>Время работы</div>
            <div class="flex items-center gap-x-[10px]">
              <div class="text-gray-primary">с</div>
              <div class="flex gap-x-[8px] items-center">
                <div class="text-xl font-medium">{{ openTime }}</div>
                <div class="flex gap-x-[6px]">
                  <button class="mini-button" :disabled="loading" @click="decreaseOpenTime()">-</button>
                  <button class="mini-button" :disabled="loading" @click="increaseOpenTime()">+</button>
                </div>
              </div>

              <div class="text-gray-primary">до</div>
              <div class="text-xl font-medium">{{ closeTime }}</div>
              <div class="flex gap-x-[6px]">
                <button class="mini-button" :disabled="loading" @click="decreaseCloseTime()">-</button>
                <button class="mini-button" :disabled="loading" @click="increaseCloseTime()">+</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="mb-[12px]">Телефон</div>
        <div class="text-sm text-gray-primary mb-[12px] text-pretty">
          Номер, по которому ваши клиенты могут связаться с вашей организацией
        </div>
        <div class="w-full">
          <AppInput v-model="phone" mask="phone"></AppInput>
        </div>
      </div>

      <div class="flex justify-end">
        <AppButton :loading="loading" :disabled="loading" @click="submit()">Сохранить</AppButton>
      </div>
    </div>
  </CabinetLayout>
</template>

<style scoped>
.mini-button {
  @apply w-[22px] h-[22px] text-sm border border-[#CBD3E4] rounded-[6px] font-normal;
}
</style>
