<script lang="ts" setup>
import AppModal from '@/components/AppModal.vue';
import { useToast } from '@/use/useToast.js';
import { computed, ref, watchEffect } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import AppInput from '@/components/AppInput.vue';
import useVuelidate from '@vuelidate/core';
import { storeClientSource, updateClientSource } from '@/api/client_source.js';
import { queryKeys } from '@/query/queryKeys.js';
import { useClientSourceQuery } from '@/query/useClientSourceQuery.js';
const props = defineProps<{
  clientSourceId?: string;
}>();

const emit = defineEmits(['close']);

const isShow = ref(true);
const queryClient = useQueryClient();
const loading = ref(false);

const close = () => {
  emit('close');
};

const { data: clientSources } = useClientSourceQuery();

const clientSource = computed(() => {
  return clientSources.value.find((clientSource) => clientSource.id === props.clientSourceId);
});

const vuelidate = useVuelidate();

const save = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  try {
    if (props.clientSourceId) {
      await updateClientSource({
        id: props.clientSourceId,
        title: title.value,
      });
    } else {
      await storeClientSource({
        title: title.value,
      });
    }

    await queryClient.invalidateQueries({ queryKey: [queryKeys.clientSources] });

    useToast().show('Успешно выполнено.');
    emit('close');
  } catch (error) {
    loading.value = false;
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};

const modalTitle = computed(() => {
  return props.clientSourceId ? 'Редактирование источника клиента' : 'Новый источник клиента';
});

const title = ref(null);

watchEffect(() => {
  if (clientSource.value) {
    title.value = clientSource.value.title;
  }
});
</script>

<template>
  <AppModal :show="isShow" @close="close()">
    <template #title>{{ modalTitle }}</template>

    <div class="flex flex-col gap-y-[24px]">
      <div class="flex flex-col gap-y-[12px]">
        <div>Название</div>
        <div>
          <AppInput v-model="title" placeholder="Введите название" required />
        </div>
      </div>

    </div>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton outline :disabled="loading" @click="close()">Отмена</AppButton>
        <AppButton :disabled="loading" :loading="loading" @click="save()">Сохранить</AppButton>
      </div>
    </template>
  </AppModal>
</template>
