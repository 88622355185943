import { formatPhone } from '@/use/useFormat.js';
import EmployeeRole from '@/enums/EmployeeRole.js';
import EmployeeStatus from '@/enums/EmployeeStatus.js';
import EmployeePayrollPeriod from '@/enums/EmployeePayrollPeriod.js';
import EmployeePayrollType from '@/enums/EmployeePayrollType.js';
import { User } from '@/interfaces/User';
import { Role } from '@/interfaces/Role';

/**
 * @todo подумать над неймингом
 * или вынести в useUser часть функций
 */

export const useEmployee = () => {
  const employeeShortName = (user: User) => {
    user = { ...user };

    if (user.first_name || user.last_name) {
      if (typeof user.first_name === 'string' && user.first_name.length > 0 && user.last_name) {
        user.first_name = user.first_name.substring(0, 1) + '.';
      }

      return [user.first_name, user.last_name].join(' ');
    }

    return null;
  };

  const employeeShortTitle = (user: User) => {
    const shortName = employeeShortName(user);

    if (shortName) {
      return shortName;
    }

    return formatPhone(user.phone);
  };

  const employeeRoleTitle = (role: Role) => {
    switch (role.id) {
      case EmployeeRole.OWNER:
        return 'Владелец';

      case EmployeeRole.MANAGER:
        return 'Управляющий';

      case EmployeeRole.ADMIN:
        return 'Администратор';

      case EmployeeRole.EMPLOYEE:
        return 'Сотрудник';
    }
  };

  const employeeStatusTitle = (status: typeof EmployeeStatus) => {
    switch (status) {
      case EmployeeStatus.WORKING:
        return 'Работает';

      case EmployeeStatus.FIRED:
        return 'Уволен';
    }
  };

  const employeePayrollPeriodTitle = (period) => {
    switch (period) {
      case EmployeePayrollPeriod.SHIFT:
        return 'Каждую смену';

      case EmployeePayrollPeriod.MONTH:
        return 'Раз в месяц';
    }
  };

  const employeePayrollTypeTitle = (type) => {
    switch (type) {
      case EmployeePayrollType.PERCENT:
        return 'Процент от выработки';

      case EmployeePayrollType.FIXED:
        return 'Фиксированная';

      case EmployeePayrollType.PERCENT_AND_FIXED:
        return 'Фиксированная и процент от выработки';

      case EmployeePayrollType.PERCENT_OR_FIXED:
        return 'Фиксированная или процент от выработки (что больше)';
    }
  };

  return {
    employeeShortTitle,
    employeeShortName,
    employeeRoleTitle,
    employeeStatusTitle,
    employeePayrollPeriodTitle,
    employeePayrollTypeTitle,
  };
};
