import { DefaultResponse } from "@/interfaces/Response";
import http from "@/libs/http";
import { ClientSource, NewClientSource, UpdatedClientSource } from "@/interfaces/ClientSource";

export const getClientSources = (): Promise<DefaultResponse<ClientSource[]>> => {
	return http.get('/api/v2/client/source');
};

export const storeClientSource = (data: NewClientSource): Promise<DefaultResponse<ClientSource>> => {
	return http.post('/api/v2/client/source', data);
};

export const updateClientSource = (data: UpdatedClientSource): Promise<DefaultResponse<ClientSource>> => {
	return http.put(`/api/v2/client/source/${data.id}`, data);
};

export const removeClientSource = (id: string): Promise<DefaultResponse<void>> => {
	return http.delete('/api/v2/client/source', {
		params: {
			id,
		},
	});
};
