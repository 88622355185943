import { useQuery } from '@tanstack/vue-query';
import { getCustomField, getCustomFields } from '@/api/customField';

export const useCustomFieldsQuery = ({ entityType } = {}, options = {}) => {
  const queryKey = ['custom-fields', { entityType }];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getCustomFields(entityType);
    },
    ...options,
  });
};

export const useCustomFieldQuery = (fieldId, options = {}) => {
  const queryKey = ['custom-fields', { fieldId }];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getCustomField(fieldId);
    },
    ...options,
  });
};
