<script setup lang="ts">
import { ref } from 'vue';
import { DateTime } from 'luxon';

interface DatePreset {
  label: string;
  days: number;
}

const datePresets: DatePreset[] = [
  { label: '3 месяца', days: 90 },
  { label: '6 месяцев', days: 180 },
  { label: 'Год', days: 365 },
];

const props = defineProps<{
  startDate: string;
  endDate: string;
}>();

const emit = defineEmits<{
  (e: 'update:startDate', value: string): void;
  (e: 'update:endDate', value: string): void;
}>();

const selectedPreset = ref<DatePreset | null>(datePresets[4]); // По умолчанию год

const updateDateRange = (preset: DatePreset) => {
  selectedPreset.value = preset;

  emit('update:startDate', DateTime.now().minus({ days: preset.days }).toISO());
  emit('update:endDate', DateTime.now().toISO());
};

const updateCustomDateRange = (start: string, end: string) => {
  selectedPreset.value = null;

  emit('update:startDate', start);
  emit('update:endDate', end);
};
</script>

<template>
  <div class="flex flex-wrap gap-6 p-4 bg-white rounded-lg shadow-sm">
    <div class="flex flex-wrap gap-2">
      <button
        v-for="preset in datePresets"
        :key="preset.label"
        class="px-4 py-2 text-sm text-gray-600 transition-all border border-gray-200 rounded-md hover:bg-gray-50"
        :class="{ 'bg-violet-50 border-violet-primary text-violet-primary': selectedPreset?.label === preset.label }"
        @click="updateDateRange(preset)"
      >
        {{ preset.label }}
      </button>
    </div>
    <div class="flex items-center gap-4">
      <div class="flex items-center gap-2">
        <label class="text-sm text-gray-600">С</label>
        <input
          type="date"
          :value="DateTime.fromISO(startDate).toFormat('yyyy-MM-dd')"
          :max="DateTime.fromISO(endDate).toFormat('yyyy-MM-dd')"
          class="w-32 px-2 py-2 text-sm text-gray-700 border border-gray-200 rounded-md focus:outline-none focus:border-violet-primary"
          @change="
            (e: Event) => updateCustomDateRange(DateTime.fromISO((e.target as HTMLInputElement).value).toISO(), endDate)
          "
        />
      </div>
      <div class="flex items-center gap-2">
        <label class="text-sm text-gray-600">По</label>
        <input
          type="date"
          :value="DateTime.fromISO(endDate).toFormat('yyyy-MM-dd')"
          :min="DateTime.fromISO(startDate).toFormat('yyyy-MM-dd')"
          :max="DateTime.now().toFormat('yyyy-MM-dd')"
          class="w-32 px-2 py-2 text-sm text-gray-700 border border-gray-200 rounded-md focus:outline-none focus:border-violet-primary"
          @change="
            (e: Event) =>
              updateCustomDateRange(startDate, DateTime.fromISO((e.target as HTMLInputElement).value).toISO())
          "
        />
      </div>
    </div>
  </div>
</template>
