<script setup>
import BookingCalendar from '@/modules/booking/components/BookingCalendar.vue';
import { useRouter } from 'vue-router';
import { DateTime } from 'luxon';
import BookingUpcoming from '@/modules/booking/components/BookingUpcoming.vue';
import BookingDaysOfTheWeekNavigation from '@/modules/booking/components/BookingDaysOfTheWeekNavigation.vue';
import BookingTable from '@/modules/booking/components/BookingTable.vue';
import { useBookingQuery } from '@/query/useBookingQuery.js';
import { useWorkplacesQuery } from '@/query/useWorkplaceQuery.js';
import { computed, ref } from 'vue';
import BookingCreate from '@/modules/booking/components/BookingCreate.vue';
import { plural } from '@/use/useFormat.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { tabs } from '@/modules/booking/booking-tabs.js';

const props = defineProps({
  date: {
    type: String,
    required: true,
  },
});

const bookingsStartTime = computed(() => DateTime.fromISO(props.date).set({ hour: 0, minute: 0, seconds: 0 }).toISO());
const bookingsEndTime = computed(() => DateTime.fromISO(props.date).plus({ day: 1 }).toISO());

const { data: bookings, isLoading: bookingsIsLoading } = useBookingQuery(
  { startTime: bookingsStartTime, endTime: bookingsEndTime },
  { keepPreviousData: true, select: (response) => response?.data?.data }
);

const { data: workplaces, isLoading: workplacesIsLoading } = useWorkplacesQuery({
  select: (response) => response?.data?.data,
});

const isLoading = computed(() => {
  return bookingsIsLoading.value || workplacesIsLoading.value;
});

const selectedIntervals = ref([]);
const router = useRouter();

const selectedDateChange = (date) => {
  selectedIntervals.value = [];
  router.push({ name: 'booking.index', params: { date } });
};

const selectedIntervalsChange = (intervals) => {
  selectedIntervals.value = intervals;
};

const bookedIntervals = computed(() => {
  return bookings.value
    ?.filter((interval) => {
      const bookingsStartTimeObject = DateTime.fromISO(bookingsStartTime.value);
      const bookingsEndTimeObject = DateTime.fromISO(bookingsEndTime.value);

      const timeStart = DateTime.fromISO(interval.startTime);

      if (timeStart < bookingsStartTimeObject || timeStart > bookingsEndTimeObject) {
        return false;
      }

      return true;
    })
    .map((interval) => {
      const duration = interval.duration / 60;

      // const timeStart = DateTime.fromISO(interval.startTime).setZone('Europe/Moscow');
      // const timeEnd = DateTime.fromISO(interval.startTime).setZone('Europe/Moscow').plus({ minutes: duration });

      const timeStart = DateTime.fromISO(interval.startTime);
      const timeEnd = DateTime.fromISO(interval.startTime).plus({ minutes: duration });

      return {
        bookingId: interval.bookingId,
        workplaceId: interval.workplaceId,
        timestamp: DateTime.fromISO(interval.startTime).toISO(),
        // timestamp: DateTime.fromISO(interval.startTime).setZone('Europe/Moscow').toISO(),
        duration,
        client: interval.client,
        timeStart: timeStart.toFormat('HH:mm'),
        timeEnd: timeEnd.toFormat('HH:mm'),
        note: interval.note,
        isOnline: interval.isOnline,
      };
    });
});

const formatSelectedDate = (date) => {
  const dateObject = DateTime.fromISO(date);

  switch (true) {
    case dateObject.hasSame(DateTime.now(), 'day'):
      return dateObject.toFormat('Сегодня, d MMMM, EEEE');

    case dateObject.hasSame(DateTime.now().plus({ day: 1 }), 'day'):
      return dateObject.toFormat('Завтра, d MMMM, EEEE');

    default:
      return dateObject.toFormat('d MMMM, EEEE');
  }
};
</script>

<template>
  <CabinetLayout
    :tabs="tabs"
    :loading="isLoading"
    hide-in-loading
  >
    <div class="grid gap-x-[16px] grid-cols-[minmax(600px,3000px)_minmax(379px,379px)]">
      <div class="flex flex-col">
        <div class="flex justify-between pb-[22px] border-b border-[#CBD3E4]">
          <div class="flex gap-x-[5px] text-lg">
            <span class="capitalize">{{ formatSelectedDate(props.date) }}</span>
            <span class="text-gray-primary"
              >({{ bookings.length }} {{ plural(bookings.length, ['запись', 'записи', 'записей']) }})</span
            >
          </div>
          <div>
            <BookingDaysOfTheWeekNavigation
              class="mx-[9px]"
              :selected-date="props.date"
              @selected-date-changed="selectedDateChange"
            />
          </div>
        </div>

        <BookingTable
          class="mt-[28px]"
          :booked-intervals="bookedIntervals"
          :workplaces="workplaces"
          :selected-date="props.date"
          :selected-intervals="selectedIntervals"
          @selected-intervals-changed="selectedIntervalsChange"
        />
      </div>

      <div class="flex flex-col gap-y-[24px]">
        <BookingCreate
          v-if="selectedIntervals.length > 0"
          class="sticky top-2"
          :selected-intervals="selectedIntervals"
          :selected-date="props.date"
          :booked-intervals="bookedIntervals"
          :workplaces="workplaces"
          @selected-intervals-changed="selectedIntervalsChange"
        />

        <BookingCalendar
          v-if="selectedIntervals.length === 0"
          :selected-date="props.date"
          @selected-date-changed="selectedDateChange"
        />

        <BookingUpcoming v-if="selectedIntervals.length === 0" />
      </div>
    </div>
  </CabinetLayout>
</template>
