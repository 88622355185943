import { useQuery } from '@tanstack/vue-query';
import { getPermissions } from '@/api/role';
import { queryKeys } from './queryKeys';

export const usePermissionsQuery = (options = {}) => {
  return useQuery({
    queryKey: [queryKeys.permissions],
    queryFn: () => getPermissions(),
    select: (response) => response.data.data,
    ...options,
  });
};
