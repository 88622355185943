<script setup>
import { useAuthStore } from '@/store/AuthStore';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const authStore = useAuthStore();
const router = useRouter();

onMounted(() => {
  return router.push(authStore.getHomeAction());
});
</script>

<template>
  <div></div>
</template>
