import http from '@/libs/http.js';
import { unref } from 'vue';

export const getCustomFields = ({ entityType } = {}) => {
  entityType = unref(entityType);

  return http.get('/api/v2/custom-fields', {
    params: {
      entityType,
    },
  });
};

export const getCustomField = (fieldId) => {
  fieldId = unref(fieldId);

  return http.get('/api/v2/custom-fields/' + fieldId);
};

export const customFieldStore = ({ entityType, fieldLabel, fieldType, isRequired, options }) => {
  return http.post('/api/v2/custom-fields/', {
    entityType,
    fieldLabel,
    fieldType,
    isRequired,
    options,
  });
};
