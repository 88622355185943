import { useQuery } from '@tanstack/vue-query';
import { getRoles, getPermissions } from '@/api/role';
import { queryKeys } from './queryKeys';

export const useRolesQuery = (options = {}) => useQuery({
  queryKey: [queryKeys.roles],
  queryFn: () => getRoles(),
  ...options,
  select: (response) => response.data.data,
});

export const usePermissionsQuery = (options = {}) => useQuery({
  queryKey: [queryKeys.permissions],
  queryFn: () => getPermissions(),
  ...options,
  select: (response) => response.data.data,
});

