<script setup>
import AppModal from '@/components/AppModal.vue';
import { useToast } from '@/use/useToast.js';
import { computed, ref, watchEffect } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import AppInput from '@/components/AppInput.vue';
import useVuelidate from '@vuelidate/core';
import { accountStore, accountUpdate } from '@/api/account.js';
import { useAccountQuery } from '@/query/useAccountQuery.js';
import AppCheckbox from '@/components/AppCheckbox.vue';

const props = defineProps({
  accountId: {
    type: Number,
    required: false,
    default: null,
  },
});

const emit = defineEmits(['closed', 'saved']);

const isShow = ref(true);
const queryClient = useQueryClient();
const loading = ref(false);

const close = () => {
  emit('closed');
};

const vuelidate = await useVuelidate();

const save = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  try {
    if (props.accountId) {
      await accountUpdate(props.accountId, {
        title: title.value,
        isArchived: isArchived.value,
      });
    } else {
      await accountStore({ title: title.value });
    }

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['account', 'order', 'shift'].indexOf(key) !== -1),
    });
    useToast().show('Счет создан');
    emit('saved');
  } catch (error) {
    loading.value = false;
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};

const modalTitle = computed(() => {
  return props.accountId ? 'Редактирование счета' : 'Новый счет';
});

const title = ref(null);
const isArchived = ref(null);

const enabled = computed(() => {
  return !!props.accountId;
});

const { data: account, isLoading } = useAccountQuery(
  { accountId: props.accountId },
  { enabled, select: (response) => response?.data?.data }
);

watchEffect(() => {
  if (!account.value) {
    return;
  }

  title.value = account.value.title;
  isArchived.value = account.value.isArchived;
});
</script>

<template>
  <AppModal
    :show="isShow"
    :loading="props.accountId && isLoading"
    @close="close()"
  >
    <template #title>{{ modalTitle }}</template>

    <div class="flex flex-col gap-y-[24px]">
      <div class="flex flex-col gap-y-[12px]">
        <div>Название счета</div>
        <div>
          <AppInput
            v-model="title"
            required
          />
        </div>
      </div>

      <div
        v-if="props.accountId"
        class="flex flex-col gap-y-[12px]"
      >
        <AppCheckbox
          v-model="isArchived"
          required
          label="В архиве"
        />
      </div>
    </div>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="save()"
          >Сохранить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
