<script setup lang="ts">
import { computed, ref } from 'vue';
import AppModal from '@/components/AppModal.vue';
import AppScrollable from '@/components/AppScrollable.vue';
import AppButton from '@/components/AppButton.vue';
import { formatPhone } from '@/use/useFormat.js';
import { useRolesQuery } from '@/query/useRoleQuery';
import { useEmployee } from '@/use/useEmployee';

interface Props {
  roleId: number;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const isShow = ref(true);
const errorMessage = ref<string | null>(null);

const { data: roles, isLoading: isRolesLoading } = useRolesQuery({
  enabled: !!props.roleId,
});

const currentRole = computed(() => roles.value?.find((role) => role.id === props.roleId));

const title = computed(() => currentRole.value?.name ?? '');

const close = () => {
  emit('close');
};

const employees = computed(() => {
  return currentRole.value?.employees ?? [];
});
</script>

<template>
  <AppModal
    :show="isShow"
    :loading="isRolesLoading"
    width="large"
    @close="close"
  >
    <template #title>
      Пользователи группы <b>{{ title }}</b>
    </template>

    <AppScrollable
      v-if="currentRole?.employees"
      class="mt-[24px] !h-[200px]"
    >
      <ul>
        <li
          v-for="employee in employees"
          :key="employee.employeeId"
          class="mt-[14px] flex items-center justify-between"
        >
          <div>{{ useEmployee().employeeShortName(employee) }} ({{ formatPhone(employee.phone) }})</div>
        </li>
      </ul>
    </AppScrollable>

    <template #actions>
      <div class="flex items-center justify-center">
        <div
          v-if="errorMessage"
          class="text-rose-500"
        >
          {{ errorMessage }}
        </div>
        <AppButton
          outline
          @click="close"
        >
          Закрыть
        </AppButton>
      </div>
    </template>
  </AppModal>
</template>
