<script setup>
import AppInputOtp from '@/components/AppInputOtp.vue';
import { computed, ref, watch } from 'vue';
import MessageService from '@/enums/MessageService.js';
import { useAuthStore } from '@/store/AuthStore';
import useVuelidate from '@vuelidate/core';
import { login, retryAuthCode } from '@/api/auth.js';
import { useToast } from '@/use/useToast.js';
import AppButton from '@/components/AppButton.vue';
import { plural } from '../../../use/useFormat.js';

const props = defineProps({
  authId: {
    type: String,
    required: true,
  },

  messengerId: {
    type: String,
    required: true,
  },

  phone: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['reset-phone', 'code-accepted']);
const code = ref(null);
const loading = ref(false);

const isMessengerSms = computed(() => {
  return props.messengerId === MessageService.SMS;
});

const isMessengerTelegram = computed(() => {
  return props.messengerId === MessageService.TELEGRAM;
});

const getMessengerText = computed(() => {
  let messengerText;

  switch (true) {
    case isMessengerTelegram.value:
      messengerText = 'Мы отправили сообщение с кодом в Telegram';
      break;

    case isMessengerSms.value:
      messengerText = 'Мы отправили сообщение с кодом в SMS';
      break;
  }

  return messengerText;
});

const authStore = useAuthStore();
const vuelidate = await useVuelidate();

const submit = async () => {
  if (!code.value || code.value.length !== 4) {
    useToast().error('Введите четырехзначный код');
    return;
  }

  loading.value = true;

  try {
    await login(props.authId, code.value);
    await authStore.refetchAuth();
    emit('code-accepted', { messengerId: props.messengerId });
  } catch (error) {
    if (error.response?.data?.message) {
      useToast().error(error.response?.data?.message);
    } else {
      throw error;
    }

    loading.value = false;
  }
};

const startTimer = () => {
  requestAgainRemaining.value = 30;
  timer.value = setInterval(() => {
    requestAgainRemaining.value--;
  }, 1000);
};

const stopTimer = () => {
  clearInterval(timer.value);
};

const handleRequestAgain = async () => {
  loading.value = true;

  try {
    const createAuthCodeResponse = await retryAuthCode(props.authId);
    if (createAuthCodeResponse.data?.messengerId) {
      startTimer();
    }
  } catch (error) {
    if (error.response?.data?.message) {
      useToast().error(error.response?.data?.message);
    }
  } finally {
    loading.value = false;
  }
};

const resetPhone = () => {
  emit('reset-phone');
};

const timer = ref(null);
const requestAgainRemaining = ref(0);

startTimer();

watch(code, () => {
  submit();
});

watch(requestAgainRemaining, () => {
  if (requestAgainRemaining.value <= 0) {
    stopTimer();
  }
});
</script>

<template>
  <div class="mx-[34px] flex flex-col items-center">
    <div class="text-xl lg:text-2xl font-medium text-center">Введите код</div>
    <div class="mt-[32px] text-gray-primary text-sm">{{ getMessengerText }}</div>
    <AppInputOtp v-model:otp="code" class="mt-[24px]" :digit-count="4" />
    <div class="mt-[24px] text-sm text-center">
      <div v-if="requestAgainRemaining > 0">
        Запросить код повторно можно
        <span class="whitespace-nowrap">через
          {{ requestAgainRemaining }}
          {{ plural(requestAgainRemaining, ['секунду', 'секунды', 'секунд']) }}</span>
      </div>
      <div v-else class="cursor-pointer text-indigo-500" @click="handleRequestAgain">
        Выслать код повторно
      </div>
    </div>
    <div class="mt-[56px] grid grid-cols-2 gap-x-[12px]">
      <AppButton outline class="w-full" @click="resetPhone()">Назад</AppButton>
      <AppButton class="w-full" :loading="loading" :disabled="loading" @click="submit()">Войти</AppButton>
    </div>
  </div>
</template>
