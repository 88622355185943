<script setup>
import { useStorageQuery } from '@/query/useStorageQuery.js';
import { computed, ref } from 'vue';
import { formatPhone } from '@/use/useFormat.js';
import { useStorage } from '@/use/useStorage.js';
import AppButton from '@/components/AppButton.vue';
import StorageReleaseModal from '@/modules/storage/components/StorageReleaseModal.vue';
import StorageStatus from '@/enums/StorageStatus.js';
import router from '@/routes.js';
import DocumentTemplateName from '@/enums/DocumentTemplateName.js';
import AppIcon from '@/components/AppIcon.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import StorageStockModal from '@/modules/storage/components/StorageStockModal.vue';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { tabs } from '@/modules/storage/storage-tabs.js';
import { useAuthStore } from '@/store/AuthStore';

const props = defineProps({
  storageId: {
    type: Number,
    required: true,
  },
});

const include = ['items', 'stock', 'order.car', 'order.car.mark', 'order.car.model', 'order.client'];
const { data, isLoading } = useStorageQuery({ storageId: props.storageId, include });

const storage = computed(() => {
  return data.value?.data;
});

const {
  getStatusTitle,
  getItemTypeTitle,
  getItemMarkTitle,
  getItemParametersTitle,
  getItemAdditionalParametersTitle,
  getStatusChangedAt,
} = useStorage();

const storageReleaseModal = ref(false);

const storagePrint = (templateName) => {
  const routeData = router.resolve({
    name: 'storage.print',
    params: { storageId: props.storageId, templateName },
  });

  window.open(routeData.href, '_blank');
};

const storageStockModalShow = ref(false);

const tabsWithCurrent = computed(() => {
  if (!storage.value) {
    return tabs;
  }

  return [
    ...tabs,
    {
      title: 'Просмотр хранения',
      route: {
        name: 'storage.show',
        params: {
          storageId: storage.value.storageId,
        },
      },
    },
  ];
});
</script>

<template>
  <CabinetLayout :loading="isLoading" :hide-in-loading="true" :tabs="tabsWithCurrent">
    <div class="w-[453px] flex flex-col gap-y-[24px]">
      <div class="border border-[#CBD3E4] rounded-[6px] px-[14px] py-[24px]">
        <div>Данные хранения №{{ storage.numberId }}</div>

        <div class="grid grid-cols-[1fr_2fr] mt-[32px] gap-y-[24px]">
          <div class="text-xs text-gray-primary">Статус</div>
          <div class="flex gap-x-[10px]">
            <span>{{ getStatusTitle(storage) }}</span>
            <span class="text-gray-primary">{{ getStatusChangedAt(storage) }}</span>
          </div>

          <div class="text-xs text-gray-primary">Автомобиль</div>
          <div>
            <template v-if="storage.order.car">
              {{ storage.order.car.title }}
              <span class="text-gray-primary">{{ storage.order.car.regNumber }}</span>
            </template>
            <template v-else>
              <span class="text-gray-primary">Не указан</span>
            </template>
          </div>

          <div class="text-xs text-gray-primary">Содержание</div>
          <div class="flex flex-col gap-y-[12px]">
            <div v-for="item in storage.items" :key="item.itemId" class="flex flex-wrap gap-y-[6px]">
              <div>{{ getItemTypeTitle(item) }} {{ item.count }} шт.</div>
              <div class="flex flex-col text-gray-primary">
                <div>{{ getItemMarkTitle(item) }} {{ getItemParametersTitle(item) }}</div>
                <div>{{ getItemAdditionalParametersTitle(item) }}</div>
              </div>
            </div>
          </div>

          <div class="text-xs text-gray-primary">Клиент</div>
          <div class="flex flex-wrap gap-[3px]">
            <template v-if="storage.order.client">
              <div>
                <router-link
                  v-if="storage.order?.client"
                  class="text-violet-primary hover:underline underline-offset-4"
                  :to="{ name: 'client.show', params: { clientId: storage.order.client.clientId } }"
                >
                  {{ storage.order.client.title }}
                </router-link>
              </div>
              <div class="text-gray-primary">{{ formatPhone(storage.order.client.phone) }}</div>
            </template>
            <template v-else>
              <span class="text-gray-primary">Не указан</span>
            </template>
          </div>
        </div>

        <div v-if="storage.status === StorageStatus.IN_STOCK" class="mt-[32px]">
          <AppButton class="w-full" outline @click="storagePrint(DocumentTemplateName.STORAGE_RECEIVED)">
            Печать договора хранения
          </AppButton>
        </div>

        <div v-if="storage.status === StorageStatus.RELEASED" class="mt-[32px] flex justify-end">
          <AppButton outline @click="storagePrint(DocumentTemplateName.STORAGE_RELEASED)">Печать акта выдачи</AppButton>
        </div>

        <StorageReleaseModal
          v-if="storageReleaseModal"
          :storage-id="props.storageId"
          @closed="storageReleaseModal = false"
          @released="storageReleaseModal = false"
        />
      </div>

      <div class="border border-[#CBD3E4] rounded-[6px] px-[14px] py-[24px]">
        <div class="flex items-center justify-between">
          <div>Место хранения</div>
          <div>
            <AppIcon
              v-if="storage.status === StorageStatus.IN_STOCK"
              :icon="IconPencil"
              class="cursor-pointer text-gray-primary hover:text-violet-primary"
              @click="storageStockModalShow = true"
            />

            <StorageStockModal
              v-if="storageStockModalShow"
              :storage-id="storage.storageId"
              @saved="storageStockModalShow = false"
              @closed="storageStockModalShow = false"
            />
          </div>
        </div>

        <div class="grid grid-cols-[1fr_2fr] mt-[32px] gap-y-[24px]">
          <div class="text-xs text-gray-primary">Склад</div>
          <div>{{ storage.stock?.title ?? '—' }}</div>

          <div class="text-xs text-gray-primary">Стелаж</div>
          <div>{{ storage.rackId ?? '—' }}</div>

          <div class="text-xs text-gray-primary">Ячейка</div>
          <div>{{ storage.cellId ?? '—' }}</div>
        </div>

        <div v-if="storage.status === StorageStatus.IN_STOCK" class="mt-[32px]">
          <AppButton class="w-full" @click="storageReleaseModal = true">Выдать с хранения</AppButton>
        </div>
      </div>
    </div>
  </CabinetLayout>
</template>
