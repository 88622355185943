import ShiftShowPage from '@/modules/shift/pages/ShiftShowPage.vue';
import ShiftPrintPage from '@/modules/shift/pages/ShiftPrintPage.vue';
import ShiftClosePage from '@/modules/shift/pages/ShiftClosePage.vue';
import ShiftIndexPage from '@/modules/shift/pages/ShiftIndexPage.vue';
import ShiftLogPage from '@/modules/shift/pages/ShiftLogPage.vue';
export default [
  {
    path: '/shift/',
    name: 'shift.index',
    component: ShiftIndexPage,
    meta: { permissions: ['shift.view'] },
  },
  {
    name: 'shift.show',
    path: '/shift/:shiftId',
    props: true,
    component: ShiftShowPage,
    meta: { permissions: ['shift.view'] },
  },
  {
    name: 'shift.open',
    path: '/shift/open',
    redirect: () => {
      return { name: 'shift.show', params: { shiftId: 'current' } };
    },
    meta: { permissions: ['shift.open'] },
  },
  {
    name: 'shift.close',
    path: '/shift/close',
    component: ShiftClosePage,
    meta: { permissions: ['shift.close'] },
  },
  {
    name: 'shift.print',
    path: '/shift/:shiftId/print',
    props: true,
    component: ShiftPrintPage,
    meta: {
      sidebar: false,
      permissions: ['shift.view'],
    },
  },
  {
    name: 'shift.log',
    path: '/shift/log',
    component: ShiftLogPage,
    meta: { permissions: ['shift.view'] },
  },
];
