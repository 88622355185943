<script setup lang="ts">
import AuthLayout from '@/layouts/AuthLayout.vue';
import IconLogo from '@/components/Icons/IconLogo.vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/AuthStore';
import AppScrollable from '@/components/AppScrollable.vue';
import AppIcon from '@/components/AppIcon.vue';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import IconPreloader from '@/components/Icons/IconPreloader.vue';
import { useToast } from '@/use/useToast.js';
import { useBusinessQuery } from '@/query/useBusinessQuery';
import { Business } from '@/interfaces/Business';

const queryClient = useQueryClient();

const loading = ref(false);
const selectedBusinessId = ref(null);
const router = useRouter();
const authStore = useAuthStore();

const { data: availableBusinesses, isSuccess } = useBusinessQuery();

const selectBusiness = async (business: Business) => {
  selectedBusinessId.value = business.id;
  loading.value = true;

  try {
    await authStore.businessSwitch(business.id);
    await authStore.refetchAuth();
    await queryClient.resetQueries();

    if (authStore.user?.first_name === null) {
      return await router.push({ name: 'auth.set-name' });
    }

    await router.push({ name: 'home.index' });
  } catch (error) {
    if (error.response?.data?.message) {
      useToast().error(error.response.data.message);
    } else {
      throw error;
    }
    selectedBusinessId.value = null;
    loading.value = false;
  }
};

if (availableBusinesses.value?.length === 1) {
  const business = availableBusinesses.value[0];
  selectBusiness(business);
}
</script>

<template>
  <AuthLayout class="flex flex-col items-center">
    <div class="mt-[68px]">
      <AppIcon :icon="IconLogo" :width="69" :height="69" class="text-[#EC4899]" />
    </div>

    <div v-if="isSuccess"
      class="flex flex-col px-[16px] md:px-0 w-full md:w-[410px] mt-[100px] md:mt-[124px] lg:mt-[146px]">
      <div v-if="availableBusinesses">
        <div class="text-xl font-medium text-center lg:text-2xl">Выберите филиал</div>

        <AppScrollable class="mt-[24px] !h-[400px]">
          <div class="flex flex-col gap-y-[8px]">
            <div v-for="business in availableBusinesses" :key="business.businessId"
              class="border border-[#CBD3E4] rounded-[6px] p-[10px] bg-white hover:bg-[#FAFBFE] cursor-pointer"
              @click="selectBusiness(business)">
              <div class="flex justify-between"
                :class="{ 'opacity-20': loading && business.businessId !== selectedBusinessId }">
                <div class="flex flex-col gap-y-[4px]">
                  <div class="text-sm">{{ business.title }}</div>
                  <div class="text-xs text-gray-primary">
                    <div>{{ business.city }}</div>
                    <div>{{ business.address }}</div>
                  </div>
                </div>
                <div class="flex items-center pr-[12px]">
                  <AppIcon v-if="business.businessId === selectedBusinessId" :icon="IconPreloader" />
                </div>
              </div>
            </div>
          </div>
        </AppScrollable>
        <div class="mt-[32px]">
          <router-link v-if="authStore.isOwner()" :to="{ name: 'auth.business-create' }"
            class="text-sm font-medium text-center cursor-pointer text-violet-primary">
            Регистрация нового филиала
          </router-link>
        </div>
      </div>
      <div v-else class="flex flex-col gap-y-[20px] items-center">
        <div class="text-xl font-medium text-center">У вас нет доступа ни к одной компании!</div>
        <div class="font-light text-center">
          Если вы сотрудник, то свяжитесь с вашим руководителем и сообщите, чтобы он дал вам доступ к компании. Если вы
          руководитель и впервые у нас на сервисе - создайте компанию.
        </div>
        <AppButton class="mt-[26px]" @click="router.push({ name: 'auth.business-create' })">Создать компанию</AppButton>
      </div>
    </div>
  </AuthLayout>
</template>
