<script setup>
import { computed, ref, unref, watchEffect } from 'vue';
import { useWorkplacesQuery } from '@/query/useWorkplaceQuery.js';
import AppButton from '@/components/AppButton.vue';
import AppInput from '@/components/AppInput.vue';
import AppCheckbox from '@/components/AppCheckbox.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import IconEquals from '@/components/Icons/IconEquals.vue';
import { Sortable } from 'sortablejs-vue3';
import AppIcon from '@/components/AppIcon.vue';
import { updateWorkplace } from '@/api/workplace.js';
import { useQueryClient } from '@tanstack/vue-query';
import { useToast } from '@/use/useToast.js';
import SettingsWorkplaceCreateModal from '@/modules/settings/components/SettingsWorkplaceCreateModal.vue';
import IconTrash from '@/components/Icons/IconTrash.vue';
import SettingsWorkplaceRemoveModal from '@/modules/settings/components/SettingsWorkplaceRemoveModal.vue';
import { tabs } from '@/modules/settings/settings-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { useBookingSettingsQuery } from '@/query/useBookingSettingsQuery.js';
import { updateBookingSettings } from '@/api/booking_settings.js';
import SettingsBookingSlotInterval from '@/modules/settings/components/SettingsBookingSlotInterval.vue';
import SettingsBookingSlug from '@/modules/settings/components/SettingsBookingSlug.vue';

const sortableOptions = computed(() => {
  return {
    animation: 150,
    handle: '.service-move',
    group: 'workplaces',
  };
});

const queryClient = useQueryClient();

const changeWorkplaceIndex = async (element) => {
  const workplaceId = element.item.dataset.workplaceId;

  try {
    await updateWorkplace(workplaceId, {
      index: element.newIndex,
    });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['workplace', 'update', 'index'].indexOf(key) !== -1),
    });

    useToast().show('Позиция изменена');
  } catch (error) {
    const items = element.from.querySelectorAll(':scope > div');
    element.from.insertBefore(element.item, items[element.oldIndex + (element.oldIndex > element.newIndex)]);

    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};

const workplaceModalShow = ref(false);
const editableWorkplace = ref(null);

const sortedWorkplaces = computed(() => {
  if (!workplaces.value) {
    return [];
  }

  const workplacesCopy = JSON.parse(JSON.stringify(workplaces.value?.data));
  const sorted = workplacesCopy.sort((a, b) => a.index - b.index);

  return sorted;
});

const workplaceModalOpen = (workplace = null) => {
  editableWorkplace.value = workplace;
  workplaceModalShow.value = true;
};

const workplaceModalClose = () => {
  workplaceModalShow.value = false;
  editableWorkplace.value = null;
};

const workplaceRemoveModalShow = ref(false);
const removableWorkplace = ref(null);

const workplaceRemoveModalOpen = (workplace) => {
  workplaceRemoveModalShow.value = true;
  removableWorkplace.value = workplace;
};

const workplaceRemoveModalClose = () => {
  workplaceRemoveModalShow.value = false;
  removableWorkplace.value = null;
};

const { data: workplaces, isLoading } = useWorkplacesQuery(
  { include: ['workplace.get'] },
  { select: (response) => response?.data?.data }
);

const { data: bookingSettings } = useBookingSettingsQuery({ select: (response) => response?.data?.data });

const allowBooking = ref();
const allowChooseWorkplace = ref();
const allowMultipleSlots = ref();
const yandexMetrikaId = ref();
const telegramChatId = ref();

watchEffect(() => {
  if (!bookingSettings.value) {
    return;
  }

  allowBooking.value = !!bookingSettings.value.allow_booking;
  allowChooseWorkplace.value = !!bookingSettings.value.allow_choose_workplace;
  allowMultipleSlots.value = !!bookingSettings.value?.allow_multiple_slots;
  yandexMetrikaId.value = bookingSettings.value?.yandex_metrika_id ?? null;
  telegramChatId.value = bookingSettings.value?.telegram_chat_id ?? null;
});

const changeBusinessSettings = async () => {
  try {
    await updateBookingSettings({
      allowBooking: unref(allowBooking),
      allowChooseWorkplace: unref(allowChooseWorkplace),
      allowMultipleSlots: unref(allowMultipleSlots),
      yandexMetrikaId: Number(unref(yandexMetrikaId)) ?? 0,
      telegramChatId: Number(unref(telegramChatId)) ?? 0,
    });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['booking-settings'].indexOf(key) !== -1),
    });
    useToast().show('Настройки обновлены');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};
</script>

<template>
  <CabinetLayout :tabs="tabs" :loading="isLoading" :hide-in-loading="true">
    <div class="flex flex-col gap-y-[32px]">
      <div class="flex flex-col bg-white max-w-[400px]">
        <div class="py-[10px] px-[8px]">Доступные места для записи</div>

        <Sortable :list="sortedWorkplaces" :options="sortableOptions" class="flex flex-col" item-key="workplaceId"
          @end="changeWorkplaceIndex">
          <template #item="{ element: workplace }">
            <div :data-workplace-id="workplace.workplaceId"
              class="flex justify-between items-center text-gray-primary py-[10px] px-[8px] hover:bg-[#FBFBFD] bg-white">
              <div class="flex gap-x-[23px] items-center">
                <AppIcon :icon="IconEquals" :width="16" :height="16" class="cursor-move service-move" />
                <div>{{ workplace.title }}</div>
              </div>
              <div class="flex gap-x-[17px]">
                <div></div>
                <div class="flex gap-x-[12px] items-center">
                  <AppIcon :width="17" :height="20" :icon="IconPencil" class="hover:text-violet-primary cursor-pointer"
                    @click="workplaceModalOpen(workplace)" />
                  <div class="w-[1px] h-[16px] bg-gray-secondary"></div>
                  <AppIcon :width="17" :height="20" :icon="IconTrash" class="hover:text-red-notify cursor-pointer"
                    @click="workplaceRemoveModalOpen(workplace)" />
                </div>
              </div>
            </div>
          </template>
        </Sortable>

        <div class="mt-[24px]">
          <AppButton @click="workplaceModalOpen(null)">Добавить место</AppButton>
        </div>

        <div class="mt-[32px]">
          <AppCheckbox v-model="allowBooking" label="Включить онлайн-запись"></AppCheckbox>
        </div>

        <template v-if="allowBooking">
          <div class="mt-[32px]">
            <SettingsBookingSlug />
          </div>

          <div class="mt-[24px]">
            <SettingsBookingSlotInterval />
          </div>

          <div class="mt-[24px]">
            <AppCheckbox v-model="allowChooseWorkplace" label="Разрешить выбор места"></AppCheckbox>
          </div>

          <div class="mt-[12px]">
            <AppCheckbox v-model="allowMultipleSlots" label="Разрешить выбор нескольких окон для записи"></AppCheckbox>
          </div>

          <div class="mt-[24px]">
            <div class="mb-[12px]">Яндекс.Метрика</div>
            <div class="text-sm text-gray-primary mb-[12px] text-pretty">Укажите цифровой код вашего счетчика</div>
            <div class="w-full">
              <AppInput v-model="yandexMetrikaId" mask="number"></AppInput>
            </div>
          </div>

          <div class="mt-[24px]">
            <div class="mb-[12px]">Telegram Chat ID</div>
            <div class="text-xs text-gray-primary mb-[12px] text-pretty">
              Укажите ID чата, куда вы хотите получать админские уведомления о новых записях.<br />
              <span class="text-xs">Обязательно добавьте бота @morspro_bot в этот чат.</span>
            </div>
            <div class="w-full">
              <AppInput v-model="telegramChatId" mask="number"></AppInput>
            </div>
          </div>
        </template>

        <div class="mt-[24px]">
          <AppButton @click="changeBusinessSettings()">Сохранить</AppButton>
        </div>
      </div>

      <SettingsWorkplaceCreateModal v-if="workplaceModalShow" class="md:w-[894px]" :workplace="editableWorkplace"
        @closed="workplaceModalClose()" @saved="workplaceModalClose()" />

      <SettingsWorkplaceRemoveModal v-if="workplaceRemoveModalShow" :workplace="removableWorkplace"
        @closed="workplaceRemoveModalClose()" @removed="workplaceRemoveModalClose()" />
    </div>
  </CabinetLayout>
</template>
