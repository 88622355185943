<script setup>
import AppIcon from '@/components/AppIcon.vue';
import IconCross from '@/components/Icons/IconCross.vue';
import AppInput from '@/components/AppInput.vue';
import { computed, ref, watch, unref } from 'vue';
import AppRadio from '@/components/AppRadio.vue';
import { useClientSourceQuery } from '@/query/useClientSourceQuery.js';

const emit = defineEmits(['client-new-updated', 'cancel']);

const firstName = ref(null);
const lastName = ref(null);
const phone = ref(null);
const source = ref(null);

const client = computed(() => {
  return {
    firstName: unref(firstName),
    lastName: unref(lastName),
    phone: unref(phone),
    source: unref(source),
  };
});

watch(
  client,
  () => {
    emit('client-new-updated', unref(client));
  },
  {
    deep: true,
  }
);

const cancel = () => {
  emit('cancel');
};

const { data: clientSources } = useClientSourceQuery();
</script>

<template>
  <div>
    <div class="flex justify-between items-center mb-[26px]">
      <div class="text-lg font-medium">Новый клиент</div>
      <div>
        <AppIcon
          :icon="IconCross"
          class="cursor-pointer"
          @click="cancel()"
        />
      </div>
    </div>

    <div class="flex flex-col gap-y-[24px]">
      <div class="flex flex-col gap-y-[12px]">
        <div class="font-medium">Имя</div>
        <div>
          <AppInput
            v-model="firstName"
            data-test="first-name"
            placeholder="Введите имя"
          />
        </div>
      </div>

      <div
        v-if="firstName"
        class="flex flex-col gap-y-[12px]"
      >
        <div class="font-medium">Фамилия</div>
        <div>
          <AppInput
            v-model="lastName"
            placeholder="Введите фамилию"
          />
        </div>
      </div>

      <div class="flex flex-col gap-y-[12px]">
        <div class="font-medium">Телефон</div>
        <div>
          <AppInput
            v-model="phone"
            data-test="phone"
            placeholder="+7"
            mask="phone"
          />
        </div>
      </div>

      <div class="flex flex-col gap-y-[12px]">
        <div class="font-medium">Источник клиента</div>
        <div>
          <AppRadio
            v-model="source"
            is-small
            :options="clientSources"
            option-label="title"
            option-value="id"
          />
        </div>
      </div>
    </div>
  </div>
</template>
