<script lang="ts" setup>
import AppModal from '@/components/AppModal.vue';
import { useToast } from '@/use/useToast.js';
import { ref, computed } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import { removeClientSource } from '@/api/client_source.js';
import { useClientSourceQuery } from '@/query/useClientSourceQuery.js';
import { queryKeys } from '@/query/queryKeys.js';

const props = defineProps<{
  clientSourceId: string;
}>();

const emit = defineEmits(['close']);

const { data: clientSources, isLoading } = useClientSourceQuery();

const clientSource = computed(() => {
  return clientSources.value.find((clientSource) => clientSource.id === props.clientSourceId);
});

const isShow = ref(true);
const queryClient = useQueryClient();
const loading = ref(false);

const close = () => {
  emit('close');
};

const remove = async () => {
  loading.value = true;

  try {
    await removeClientSource(props.clientSourceId);
    await queryClient.invalidateQueries({ queryKey: [queryKeys.clientSources] });

    useToast().show('Успешно выполнено.');
    emit('close');
  } catch (error) {
    loading.value = false;

    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};
</script>

<template>
  <AppModal :loading="isLoading" :show="isShow" @close="close()">
    <template #title>Удаление источника клиента</template>

    <div class="text-center text-gray-primary">
      Вы действительно хотите удалить <span class="font-medium whitespace-nowrap">{{ clientSource.title }}</span>?
    </div>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton outline :disabled="loading" @click="close()">Отмена</AppButton>
        <AppButton :disabled="loading" :loading="loading" red outline @click="remove()">Удалить</AppButton>
      </div>
    </template>
  </AppModal>
</template>
