<script setup>
import AppIcon from '@/components/AppIcon.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import IconLogo from '@/components/Icons/IconLogo.vue';
import IconTelegram from '@/components/Icons/IconTelegram.vue';
import AppButton from '@/components/AppButton.vue';
import { useRouter } from 'vue-router';
import { useTelegramConnectionLinkQuery } from '@/query/useTelegramQuery.js';
import { useAuthQuery } from '@/query/useAuthQuery.js';
import { watch } from 'vue';
import { useAuthStore } from '@/store/AuthStore';

const { data: connectionLink, isLoading } = useTelegramConnectionLinkQuery({
  select: (response) => response?.data?.data,
});

const { data: auth } = useAuthQuery({ refetchInterval: 1000, select: (response) => response?.data?.data });

const router = useRouter();
const authStore = useAuthStore();

watch(
  auth,
  () => {
    console.log(auth.value?.employee);
    if (auth.value?.employee?.telegramId) {
      router.push({ name: 'home.index' });
    }
  },
  { deep: true }
);

const showQrCode = () => {
  // window.open(connectionLink.link);
  router.push({ name: 'auth.connect-telegram-link' });
};

const redirectToHome = () => {
  const homeRouteParams = authStore.getHomeAction();
  router.push(homeRouteParams);
};
</script>

<template>
  <AuthLayout v-if="connectionLink" class="flex flex-col items-center px-[10px]">
    <div class="mt-[68px]">
      <AppIcon :icon="IconLogo" :width="69" :height="69" class="text-[#EC4899]" />
    </div>
    <div class="mt-[100px] md:mt-[124px] lg:mt-[146px]">
      <AppIcon :icon="IconTelegram" :width="67" :height="67" />
    </div>
    <div class="mt-[24px] text-2xl font-medium text-center">Подключите свой Telegram</div>
    <div class="mt-[32px] text-gray-primary text-center leading-[140%]">
      Мы будем отправлять в Telegram коды авторизации. <br />Это проще, быстрее и безопаснее.
    </div>
    <div class="mt-[32px] flex gap-[12px]">
      <AppButton outline @click="redirectToHome()">Пропустить</AppButton>
      <a :href="connectionLink.link" @click="showQrCode()">
        <AppButton>Подключить</AppButton>
      </a>
    </div>
  </AuthLayout>
</template>
