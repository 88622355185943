<script setup lang="ts">
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import AppTable from '@/components/AppTable.vue';
import AppPagination from '@/components/AppPagination.vue';
import { plural } from '@/use/useFormat.js';
import { useClientsQuery } from '@/query/useClientQuery.js';
import { clientsColumns } from '@/modules/client/tables/clientsTable.js';
import AppInput from '@/components/AppInput.vue';
import { useConfirmDialog, watchDebounced } from '@vueuse/core';
import { tabs } from '@/modules/client/client-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import AppButton from '@/components/AppButton.vue';
import ClientNewModal from '@/modules/client/components/ClientNewModal.vue';

const include = ['booking'];
const currentPage = ref(1);
const perPage = 100;
const options = { keepPreviousData: true };
const search = ref(null);
const searchDebounced = ref(null);

watchDebounced(
  search,
  () => {
    searchDebounced.value = search.value;
  },
  { debounce: 700, maxWait: 10000 },
);

const sort = 'created_at';
const order = 'desc';

const { data, isLoading, isFetching, isSuccess } = useClientsQuery(
  { searchQuery: searchDebounced, perPage, page: currentPage, include, order, sort },
  options,
);

const clients = ref(null);
const total = ref(0);

watch(
  () => [data.value, isSuccess.value],
  ([value, isSuccess]) => {
    if (isSuccess) {
      clients.value = value?.data;
      total.value = value?.meta?.total;
    }
  },
  { immediate: true },
);

const router = useRouter();
const { isRevealed, reveal, cancel } = useConfirmDialog();
</script>

<template>
  <CabinetLayout :loading="isLoading || isFetching" :hide-in-loading="!clients" :tabs="tabs">
    <div class="flex flex-wrap gap-[18px] justify-between items-center text-lg">
      <div class="flex gap-x-[12px] items-center">
        <div>{{ total }} {{ plural(total, ['клиент найден', 'клиента найдено', 'клиентов найдено']) }}</div>

        <AppInput
          v-model="search"
          class="md:w-[450px]"
          placeholder="Поиск по гос. номеру, ФИО и телефону"
          data-test="client-search"
        />
      </div>
      <AppButton v-if="false" outline @click="reveal()">Новый клиент</AppButton>
      <ClientNewModal v-if="isRevealed" @close="cancel()" />
    </div>

    <AppTable
      class="mt-[24px]"
      :data="clients"
      :columns="clientsColumns"
      row-class="cursor-pointer"
      @row-clicked="(client) => router.push({ name: 'client.show', params: { clientId: client.clientId } })"
    ></AppTable>

    <div class="flex justify-end mt-[24px]">
      <AppPagination v-model="currentPage" :total="total" :per-page="perPage" />
    </div>
  </CabinetLayout>
</template>
