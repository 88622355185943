import http from '@/libs/http.js';
import { Employee, GetEmployeesParams, UpdateEmployeeParams } from '@/interfaces/Employee';
import { DefaultResponse } from '@/interfaces/Response';
export const getEmployees = (params: GetEmployeesParams): Promise<DefaultResponse<Employee[]>> => {
  return http.get('/api/v2/employee', {
    params,
  });
};

export const getCurrentEmployee = (): Promise<DefaultResponse<Employee>> => {
  return http.get('/api/v2/employee/current');
};

export const getEmployee = (employeeId: string): Promise<DefaultResponse<Employee>> => {
  return http.get('/api/v2/employee/' + employeeId);
};

export const updateEmployee = (employeeId: string, params: UpdateEmployeeParams): Promise<DefaultResponse<Employee>> =>
  http.put('/api/v2/employee/' + employeeId, params);

export const getEmployeesAccruals = () => {
  return http.get('/api/v2/business/employee-accrual');
};

export const getEmployeesPayrolls = () => {
  return http.get('/api/v2/business/employee-payroll');
};

export const fireEmployee = (employeeId: string, reason: string) => {
  return http.post('/api/v2/employee/fire', {
    employeeId,
    reason,
  });
};

export const unfireEmployee = (employeeId: string) => {
  return http.post('/api/v2/employee/unfire', {
    employeeId,
  });
};
