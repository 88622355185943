<script setup lang="ts">
import { useRolesQuery } from '@/query/useRoleQuery.js';
import AppTable from '@/components/AppTable.vue';
import { ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import AppConfirm from '@/components/AppConfirm.vue';
import AppMessageBox from '@/components/AppMessageBox.vue';
import { rolesColumns } from '@/modules/settings/tables/rolesTable';
import { useQueryClient } from '@tanstack/vue-query';
import { useToast } from '@/use/useToast.js';
import AppIcon from '@/components/AppIcon.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import IconTrash from '@/components/Icons/IconTrash.vue';
import IconSpinner from '@/components/Icons/IconSpinner.vue';
import { tabs } from '@/modules/settings/settings-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import SettingsRolesCreateModal from '@/modules/settings/components/SettingsRolesCreateModal.vue';
import SettingsRolesEmployeesModal from '@/modules/settings/components/SettingsRolesEmployeesModal.vue';
import { roleDelete } from '@/api/role';
import { plural } from '@/use/useFormat';
import type { Role } from '@/interfaces/Role';
import { queryKeys } from '@/query/queryKeys';

const queryClient = useQueryClient();
const confirmDeleteGroupModal = ref(null);
const warningDeleteGroupModal = ref(null);

const options = { keepPreviousData: true };
const { data: roles, isLoading, isFetching } = useRolesQuery(options);

const roleEmployeesModal = ref<number | null>(null);
const createRoleModal = ref<number | null>(null);
const deleteLoading = ref(false);

const closeModal = () => {
  createRoleModal.value = null;
  roleEmployeesModal.value = null;
};

const showCreateRoleModal = (role?: Role) => {
  createRoleModal.value = role?.id ?? 0;
};

const showRoleEmployeesModal = (role: Role) => {
  roleEmployeesModal.value = role.id ?? 0;
};

const deleteRole = async (role: Role) => {
  if (role.employees.length) {
    return await warningDeleteGroupModal.value.initConfirm();
  }

  const isConfirmed = await confirmDeleteGroupModal.value.initConfirm();
  if (!isConfirmed) return;

  deleteLoading.value = true;

  try {
    await roleDelete(role.id);

    await queryClient.invalidateQueries({ queryKey: [queryKeys.roles] });

    useToast().show('Группа удалена.');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  } finally {
    deleteLoading.value = false;
  }
};
</script>

<template>
  <CabinetLayout
    :tabs="tabs"
    :loading="isLoading || isFetching"
    :hide-in-loading="isLoading === true"
  >
    <div>
      <AppTable
        v-if="roles"
        class="text-sm"
        :data="roles"
        :columns="rolesColumns"
      >
        <template #row-name="{ raw: role }">
          <div>
            <span class="mr-2 text-lg">{{ role.title }}</span>
            <span
              class="text-sm cursor-pointer text-gray-primary"
              @click="showRoleEmployeesModal(role as Role)"
            >
              {{ role.employees?.length }}
              {{ plural(role.employees?.length, ['пользователь', 'пользователя', 'пользователей']) }}</span
            >
          </div>
        </template>

        <template #row-actions-edit="{ raw: role }">
          <div class="flex gap-x-[12px] items-center">
            <AppIcon
              :icon="IconPencil"
              :data-test="`role-edit-${role.label}`"
              class="cursor-pointer hover:text-violet-primary"
              @click="showCreateRoleModal(role as Role)"
            />
          </div>
        </template>

        <template #row-actions-delete="{ raw: role }">
          <div class="flex gap-x-[12px] items-center">
            <AppIcon
              v-if="!deleteLoading"
              :icon="IconTrash"
              :data-test="`role-delete-${role.label}`"
              class="cursor-pointer hover:text-violet-primary"
              @click="deleteRole(role as Role)"
            />
            <AppIcon
              v-else
              :icon="IconSpinner"
            />
          </div>
        </template>
      </AppTable>
      <div class="flex justify-start">
        <AppButton
          class="mt-[24px]"
          @click="showCreateRoleModal"
        >
          Добавить роль
        </AppButton>
      </div>

      <SettingsRolesCreateModal
        v-if="createRoleModal !== null"
        :role-id="createRoleModal"
        @close="closeModal"
      />

      <SettingsRolesEmployeesModal
        v-if="roleEmployeesModal !== null"
        :role-id="roleEmployeesModal"
        @close="closeModal"
      />

      <AppConfirm ref="confirmDeleteGroupModal">
        <template #title>Удаление группы</template>
        <template #content>Вы действительно хотите удалить группу?</template>
      </AppConfirm>

      <AppMessageBox ref="warningDeleteGroupModal">
        <template #title>Ошибка</template>
        <template #content>
          Невозможно удалить группу с активными пользователями. Сначала удалите всех пользователей из группы.
        </template>
      </AppMessageBox>
    </div>
  </CabinetLayout>
</template>
