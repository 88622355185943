<template>
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.60005 7.7999V6.88562C3.60005 3.84671 6.00862 1.3999 9.00005 1.3999C11.9915 1.3999 14.4001 3.84671 14.4001 6.88562V7.7999M3.60005 7.7999C2.61005 7.7999 1.80005 8.62276 1.80005 9.62847V18.7713C1.80005 19.777 2.61005 20.5999 3.60005 20.5999H14.4001C15.3901 20.5999 16.2001 19.777 16.2001 18.7713V9.62847C16.2001 8.62276 15.3901 7.7999 14.4001 7.7999M3.60005 7.7999H14.4001M9.00005 15.1999V12.7999"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
