<script setup>
import { useClientsQuery } from '@/query/useClientQuery.js';
import { ref } from 'vue';
import AppSelect from '@/components/AppSelect.vue';
import { formatPhone } from '@/use/useFormat.js';
import { DateTime } from 'luxon';

const emit = defineEmits(['client-selected']);

const searchQuery = ref(null);
const include = 'booking';

const { data: clients } = useClientsQuery(
  { searchQuery, limit: 10, include },
  { select: (response) => response?.data?.data, keepPreviousData: true }
);

const search = (query) => {
  searchQuery.value = query;
};

const selectClient = (clientId) => {
  emit('client-selected', clientId);
};

const selectedClient = ref(null);
</script>

<template>
  <div>
    <AppSelect
      v-model="selectedClient"
      searchable
      option-value="clientId"
      option-label="title"
      placeholder="Не выбран (Анонимный)"
      placeholder-focused="Поиск по имени и номеру телефона"
      :options="clients ?? []"
      @search-query-changed="search"
      @changed="selectClient"
    >
      <template #default="{ option }">
        <div class="flex flex-col gap-y-[6px]">
          <div>
            {{ option.title }}
          </div>
          <div class="text-sm text-gray-primary">
            {{ formatPhone(option.phone) }}
          </div>
          <div class="text-sm text-gray-primary">Всего записей: {{ option.bookingsCount }}</div>
          <div
            v-if="option.lastBookedAt"
            class="text-sm text-gray-primary"
          >
            Последняя бронь:
            {{ DateTime.fromISO(option.lastBookedAt).toFormat('d MMMM y') }}
          </div>
        </div>
      </template>
    </AppSelect>
  </div>
</template>
