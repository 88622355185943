<script setup>
import { useOrderQuery } from '@/query/useOrderQuery.js';
import { computed, watch, ref } from 'vue';
import OrderEditCarClass from '@/modules/order/components/OrderEditCarClass.vue';
import OrderEditWheelDiameter from '@/modules/order/components/OrderEditWheelDiameter.vue';
import OrderEditCategories from '@/modules/order/components/OrderEditCategories.vue';
import OrderEditServices from '@/modules/order/components/OrderEditServices.vue';
import OrderEditWorks from '@/modules/order/components/OrderEditWorks.vue';
import OrderEditEmployee from '@/modules/order/components/OrderEditEmployee.vue';
import { formatMoney, formatPhone } from '@/use/useFormat.js';
import AppIcon from '@/components/AppIcon.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import IconArrowRefresh from '@/components/Icons/IconArrowRefresh.vue';
import { DateTime } from 'luxon';
import AppTooltip from '@/components/AppTooltip.vue';
import OrderEditClientChange from '@/modules/order/components/OrderEditClientChange.vue';
import AppButton from '@/components/AppButton.vue';
import IconTrash from '@/components/Icons/IconTrash.vue';
import OrderEditPayment from '@/modules/order/components/OrderEditPayment.vue';
import OrderStatus from '@/enums/OrderStatus.js';
import useVuelidate from '@vuelidate/core';
import { useQueryClient } from '@tanstack/vue-query';
import { orderComplete } from '@/api/order.js';
import { useToast } from '@/use/useToast.js';
import OrderRemoveModal from '@/modules/order/components/OrderRemoveModal.vue';
import OrderEditSaved from '@/modules/order/components/OrderEditSaved.vue';
import { useOrder } from '@/use/useOrder.js';
import IconFileDown from '@/components/Icons/IconFileDown.vue';
import router from '@/routes.js';
import { useAuthStore } from '@/store/AuthStore';
import { tabs } from '@/modules/order/order-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import AppClientEditModal from '@/components/AppClientEditModal.vue';

const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
});

const include = ['works', 'employees', 'client', 'car', 'payments.account'];
const { data, isLoading } = useOrderQuery({ orderId: props.orderId, include });

const order = computed(() => {
  return data?.value?.data;
});

const selectedCarClass = ref(null);

watch(
  order,
  () => {
    selectedCarClass.value = order.value?.carClass;
  },
  {
    immediate: true,
  },
);

const carClassChange = (carClass) => {
  selectedCarClass.value = carClass;
};

const selectedWheelDiameter = ref(null);

watch(
  order,
  () => {
    selectedWheelDiameter.value = order.value?.wheelDiameter;
  },
  {
    immediate: true,
  },
);

const wheelDiameterChange = (wheelDiameter) => {
  selectedWheelDiameter.value = wheelDiameter;
};

const categoryId = ref(null);

const selectedEmployeesId = computed(() => {
  return order?.value?.employees?.map((employee) => {
    return employee.employee_id;
  });
});

const editableClientId = ref(null);
const clientChange = ref(false);

const isBaseParamsSelected = computed(() => {
  return selectedCarClass.value && selectedWheelDiameter.value;
});

const paymentModalShow = ref(false);

const isOrderPaid = computed(() => {
  return (order.value.price > 0 || order.value.discountPercent === 100) && order.value.amountPaid === order.value.price;
});

const isOrderPartialPaid = computed(() => {
  return order.value.price > 0 && order.value.amountPaid > 0 && order.value.amountPaid !== order.value.price;
});

const orderPaymentsAccounts = computed(() => {
  const groupedPayments = {};

  order.value?.payments.forEach((payment) => {
    if (!groupedPayments[payment.account.accountId]) {
      groupedPayments[payment.account.accountId] = {
        ...payment.account,
        total: 0,
      };
    }

    groupedPayments[payment.account.accountId].total += payment.amount;
  });

  return Object.values(groupedPayments);
});

const isOrderCompleted = computed(() => {
  return order.value.status === OrderStatus.COMPLETED;
});

const vuelidate = useVuelidate();
const loading = ref(false);
const queryClient = useQueryClient();

const { goToOrder } = useOrder();

const orderSetComplete = async () => {
  if (!(await vuelidate.value.$validate())) {
    return false;
  }

  loading.value = true;

  try {
    await orderComplete(props.orderId);
    goToOrder(order.value, 'show');
  } catch (error) {
    loading.value = false;

    if (error?.response?.data?.message) {
      useToast().error(error.response.data.message);
    }
  } finally {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['order'].indexOf(key) !== -1),
    });
  }
};

const removeModalShow = ref(false);

const orderPrint = () => {
  const routeData = router.resolve({
    name: 'order.print',
    params: { orderId: order.value.orderId },
  });

  window.open(routeData.href, '_blank');
};

const tabsWithCurrent = computed(() => {
  if (!order.value) {
    return tabs;
  }

  return [
    ...tabs,
    {
      title: 'Редактирование заказа',
      route: {
        name: 'order.edit',
        params: {
          orderId: order.value.orderId,
        },
      },
    },
  ];
});
</script>

<template>
  <CabinetLayout :tabs="tabsWithCurrent" :loading="isLoading" :hide-in-loading="true">
    <div class="flex flex-col gap-y-[24px] grow">
      <div class="flex gap-[32px]">
        <div class="flex items-center gap-x-[10px]">
          <span>Заказ №{{ order.numberId }}</span>
          <span class="text-base font-normal">
            (от {{ DateTime.fromISO(order.createdAt).toFormat('d MMMM yyyy, HH:mm') }})
          </span>
        </div>

        <div class="flex justify-between">
          <div class="flex gap-x-[24px]">
            <div v-if="order.client" class="flex gap-x-[12px] items-center">
              <div v-if="order.client.title" class="text-lg font-medium">
                {{ order.client.title }}
              </div>
              <div v-else class="text-lg text-gray-primary">Клиент без имени</div>
              <div class="text-gray-primary">{{ formatPhone(order.client.phone) }}</div>
              <div>{{ order.car?.title ?? 'Автомобиль не указан' }}</div>
              <div v-if="order.car?.regNumber" class="text-gray-primary">
                {{ order.car.regNumber }}
              </div>
            </div>
            <div v-else>Клиент не выбран</div>

            <div class="flex gap-x-[12px] items-center">
              <AppTooltip title="Редактировать данные клиента">
                <AppIcon
                  v-if="order.client"
                  :width="18"
                  :height="17"
                  :icon="IconPencil"
                  class="cursor-pointer hover:text-violet-primary"
                  @click="editableClientId = order.client.clientId"
                />
              </AppTooltip>

              <AppClientEditModal
                v-if="editableClientId"
                :fields="['firstName', 'lastName', 'patronymicName', 'phone', 'note']"
                :client-id="editableClientId"
                @closed="editableClientId = null"
              />

              <div v-if="order.client" class="h-[16px] w-[1px] bg-gray-secondary"></div>

              <AppTooltip :title="order.client ? 'Выбрать другого клиента' : 'Выбрать клиента'">
                <AppIcon
                  :width="16"
                  :height="16"
                  :icon="IconArrowRefresh"
                  class="cursor-pointer hover:text-violet-primary"
                  @click="clientChange = true"
                />
              </AppTooltip>

              <OrderEditClientChange v-if="clientChange" :order-id="order.orderId" @closed="clientChange = false" />
            </div>
          </div>
        </div>
      </div>

      <OrderEditCarClass :order-id="order.orderId" :car-class="order.carClass" @car-class-changed="carClassChange" />

      <OrderEditWheelDiameter
        :order-id="order.orderId"
        :wheel-diameter="order.wheelDiameter"
        @wheel-diameter-changed="wheelDiameterChange"
      />

      <template v-if="isBaseParamsSelected">
        <OrderEditCategories :category-id="categoryId" @category-changed="(value) => (categoryId = value)" />

        <div class="flex flex-col gap-y-[14px] grow">
          <div class="grid grid-cols-[550px_2fr] gap-x-[27px] grow min-h-[200px]">
            <div class="flex flex-col grow">
              <OrderEditServices
                v-if="categoryId"
                :order-id="orderId"
                :category-id="categoryId"
                :car-class="selectedCarClass"
                :wheel-diameter="selectedWheelDiameter"
              />
              <OrderEditSaved v-if="order.status !== OrderStatus.DRAFT" :updated-at="order.updatedAt" />
            </div>

            <div class="flex flex-col grow gap-y-[12px]">
              <OrderEditWorks :works="order.works" />

              <div class="flex flex-col justify-center items-end gap-y-[18px]">
                <div class="flex items-center justify-end gap-x-[10px]">
                  <div class="text-lg">
                    <span>Итого: {{ formatMoney(order.price ?? 0) }}</span>
                  </div>

                  <div v-if="!isOrderPaid && !isOrderPartialPaid && order.amountDebt > 0" class="text-gray-primary">
                    Ожидает оплату
                  </div>

                  <div v-if="isOrderPartialPaid" class="text-[#FBBF26]">Частично оплачен</div>

                  <div v-if="isOrderPaid && isOrderCompleted" class="text-[#44B400]">Оплачен и завершен</div>

                  <div v-if="isOrderPaid && !isOrderCompleted" class="text-[#44B400]">Оплачен</div>

                  <div v-if="!isOrderPaid && orderPaymentsAccounts && orderPaymentsAccounts.length > 0">
                    (
                    <span v-for="(account, index) in orderPaymentsAccounts" :key="account.accountId">
                      <span v-if="index > 0">,</span>
                      {{ account.title }}
                      <span class="text-gray-primary">{{ formatMoney(account.total) }}</span>
                    </span>
                    )
                  </div>
                </div>

                <div class="flex flex-wrap items-center justify-end gap-[12px]">
                  <OrderEditEmployee :order-id="order.orderId" :selected-employees="selectedEmployeesId" />

                  <div class="w-[1px] h-[16px] bg-[#D9D9D9] hidden xl:block"></div>

                  <div class="flex gap-x-[12px]">
                    <AppButton v-if="!isOrderPaid" :disabled="order.amountDebt === 0" @click="paymentModalShow = true">
                      Принять оплату
                    </AppButton>

                    <AppButton v-if="isOrderPaid" outline @click="paymentModalShow = true">Платежи</AppButton>

                    <AppButton
                      v-if="(isOrderPaid || order.discountPercent === 100) && !isOrderCompleted"
                      :loading="loading"
                      :disabled="loading"
                      @click="orderSetComplete()"
                    >
                      Завершить заказ
                    </AppButton>

                    <OrderEditPayment
                      v-if="paymentModalShow"
                      :order="order"
                      :is-order-paid="isOrderPaid"
                      @closed="paymentModalShow = false"
                    />

                    <AppTooltip
                      v-if="order.status !== OrderStatus.COMPLETED"
                      class="flex grow"
                      title="Распечатать предварительный заказ"
                    >
                      <AppButton outline @click="orderPrint()">
                        <AppIcon :icon="IconFileDown" class="text-violet-primary" />
                      </AppButton>
                    </AppTooltip>

                    <AppButton v-if="useAuthStore().isOwner()" outline red @click="removeModalShow = true">
                      <AppIcon :icon="IconTrash" />
                    </AppButton>
                    <OrderRemoveModal
                      v-if="removeModalShow"
                      :order-id="order.orderId"
                      @closed="removeModalShow = false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div v-if="!isBaseParamsSelected" class="flex items-center justify-center grow">
        <span class="text-lg text-gray-primary">Выберите тип автомобиля и диаметр колеса</span>
      </div>
    </div>
  </CabinetLayout>
</template>
