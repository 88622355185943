<script setup>
import AppModal from '@/components/AppModal.vue';
import { useToast } from '@/use/useToast.js';
import { computed, ref, unref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import AppInput from '@/components/AppInput.vue';
import useVuelidate from '@vuelidate/core';
import { serviceCategoryStore, serviceCategoryUpdate } from '@/api/service_category.js';
import { useServiceCategoryQuery } from '@/query/useServiceCategoryQuery.js';
import AppSelect from '@/components/AppSelect.vue';
import { FieldTypes, getLabel } from '@/enums/FieldTypes';
import { customFieldStore } from '@/api/customField';
import { useCustomFieldQuery } from '@/query/useCustomFieldQuery';

const props = defineProps({
  fieldId: {
    type: Number,
    required: false,
    default: null,
  },
});

const emit = defineEmits(['closed', 'saved']);

const isShow = ref(true);
const queryClient = useQueryClient();
const loading = ref(false);

const close = () => {
  emit('closed');
};

const fieldLabel = ref(null);
const fieldType = ref(null);
const isRequired = ref(null);
const options = ref(null);

const vuelidate = await useVuelidate();

const save = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  try {
    if (props.fieldId) {
      // await serviceCategoryUpdate(props.categoryId, {
      //   title: title.value,
      // });
    } else {
      await customFieldStore({
        entityType: 'booking',
        fieldLabel: unref(fieldLabel),
        fieldType: unref(fieldType),
        isRequired: unref(isRequired),
        options: unref(options),
      });
    }

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['custom-fields'].indexOf(key) !== -1),
    });
    useToast().show('Данные сохранены');
    emit('saved');
  } catch (error) {
    loading.value = false;
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};

const modalTitle = computed(() => {
  return props.fieldId ? 'Редактирование поля' : 'Новое поле';
});

const enabled = computed(() => {
  return !!props.fieldId;
});

const { data: field, isLoading } = useCustomFieldQuery(props.fieldId, {
  enabled,
  select: (response) => response?.data?.data,
});

watchEffect(() => {
  if (!field.value) {
    return;
  }

  fieldLabel.value = field.value.field_label;
  fieldType.value = field.value.field_type;
});

const fieldTypes = computed(() => {
  return Object.values(FieldTypes).map((type) => {
    return {
      label: getLabel(type),
      value: type,
    };
  });
});
</script>

<template>
  <AppModal
    :show="isShow"
    :loading="props.fieldId && isLoading"
    @close="close()"
  >
    <template #title>{{ modalTitle }}</template>

    <div class="flex flex-col gap-y-[24px]">
      <div class="flex flex-col gap-y-[24px]">
        <div class="flex flex-col gap-y-[12px]">
          <div>Название поля</div>
          <div>
            <AppInput
              v-model="fieldLabel"
              required
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-y-[24px]">
        <div class="flex flex-col gap-y-[12px]">
          <div>Тип поля</div>
          <div>
            <AppSelect
              v-model="fieldType"
              :options="fieldTypes"
              required
            />
          </div>
        </div>
      </div>

      <div
        class="flex flex-col gap-y-[24px]"
        v-if="fieldType === FieldTypes.SELECT"
      >
        <div class="flex flex-col gap-y-[12px]">
          <div class="flex flex-col gap-y-[3px]">
            <div>Значения</div>
            <div class="text-sm text-gray-primary">Укажите значения выпадающего списка через запятую</div>
          </div>
          <div>
            <AppInput
              v-model="options"
              required
            />
          </div>
        </div>
      </div>
    </div>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          @click="close()"
          >Отмена</AppButton
        >
        <AppButton
          :disabled="loading"
          :loading="loading"
          @click="save()"
          >Сохранить</AppButton
        >
      </div>
    </template>
  </AppModal>
</template>
