import { useQuery } from '@tanstack/vue-query';
import { getServiceCategories, getServiceCategory } from '@/api/service_category.js';

export function useServiceCategoryQuery({ categoryId, include } = {}, options = {}) {
  const queryKey = ['service', 'service-category', categoryId, include];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getServiceCategory(categoryId, { include });
    },
    ...options,
  });
}

export function useServiceCategoriesQuery({ include } = {}, options = {}) {
  const queryKey = ['service', 'service-category', include];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getServiceCategories(include);
    },
    ...options,
  });
}
