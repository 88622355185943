const TEXT = 'text';
const PHONE = 'phone';
const SELECT = 'select';
const NUMBER = 'number';

export const FieldTypes = {
  TEXT,
  NUMBER,
  PHONE,
  SELECT,
};

export const getLabel = (type) => {
  switch (type) {
    case TEXT:
      return 'Текст';

    case NUMBER:
      return 'Число';

    case PHONE:
      return 'Телефон';

    case SELECT:
      return 'Выбор значений из списка';

    default:
      throw 'Неизвестный тип';
  }
};
