<script setup>
import { computed, ref, watch } from 'vue';
import { useShiftEmployeesAccrualsCalculateQuery, useShiftQuery } from '@/query/useShiftQuery.js';
import { useAccountsQuery } from '@/query/useAccountQuery.js';
import AppStatsCard from '@/components/AppStatsCard.vue';
import AppTable from '@/components/AppTable.vue';
import AppButton from '@/components/AppButton.vue';
import AppCheckbox from '@/components/AppCheckbox.vue';
import { formatMoney, formatPhone } from '@/use/useFormat.js';
import { useEmployeesQuery } from '@/query/useEmployeeQuery.js';
import { closeShift } from '@/api/shift.js';
import router from '@/routes.js';
import { DateTime } from 'luxon';
import { useToast } from '@/use/useToast.js';
import { useQueryClient } from '@tanstack/vue-query';
import { tabs } from '@/modules/shift/shift-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { queryKeys } from '@/query/queryKeys';

const shiftInclude = ['orders.payments', 'expenses'];
const options = { select: (response) => response.data?.data };

const {
  data: shift,
  isLoading: shiftIsLoading,
  isError,
  error,
} = useShiftQuery({ shiftId: 'current', include: shiftInclude }, options);

const { data: accounts, isLoading: accountsIsLoading } = useAccountsQuery({ isArchived: false }, options);

const { data: employeesAccruals, isLoading: employeesAccrualsIsLoading } =
  useShiftEmployeesAccrualsCalculateQuery(options);

const { data: employees, isLoading: employeesIsLoading } = useEmployeesQuery({}, options);

const isLoading = computed(() => {
  return (
    shiftIsLoading.value || accountsIsLoading.value || employeesAccrualsIsLoading.value || employeesIsLoading.value
  );
});

watch(isError, () => {
  if (error.value?.response?.status === 404) {
    router.push({ name: 'shift.open' });
  }
});

const cashboxAccount = computed(() => {
  if (!accounts.value) {
    return;
  }

  return accounts.value.find((account) => account.isCashbox);
});

const totalIncome = computed(() => {
  if (!shift.value?.orders) {
    return null;
  }

  return Object.values(shift.value.orders).reduce((total, order) => {
    return total + order.price;
  }, 0);
});

const accountsIncome = computed(() => {
  if (!shift.value?.orders) {
    return null;
  }

  if (!accounts.value) {
    return null;
  }

  const accountIncomes = [];

  accounts.value.forEach((account) => {
    const accountTotal = Object.values(shift.value.orders).reduce((accountSum, order) => {
      const orderSum = order.payments.reduce((orderTotal, payment) => {
        if (payment.accountId !== account.accountId) {
          return orderTotal;
        }

        return orderTotal + payment.amount;
      }, 0);

      return accountSum + orderSum;
    }, 0);

    // скрывваем счета в архиве с балансом 0
    if (account.isArchived && accountTotal === 0) {
      return;
    }

    accountIncomes.push({
      ...account,
      total: accountTotal,
    });
  });

  return accountIncomes;
});

const totalExpense = computed(() => {
  if (!shift.value) {
    return null;
  }

  return Object.values(shift.value?.expenses).reduce((total, expense) => {
    return total + expense.amount;
  }, 0);
});

const totalAccruals = computed(() => {
  if (!employeesAccruals.value) {
    return null;
  }

  return Object.values(employeesAccruals.value).reduce((total, accrual) => {
    return total + accrual.total;
  }, 0);
});

const employeesWithAccruals = computed(() => {
  if (!shift.value || !employeesAccruals.value || !employees.value) {
    return null;
  }

  return employeesAccruals.value.map((accrual) => {
    const employee = employees.value.find((employee) => employee.employeeId === accrual.employee.employeeId);

    return {
      ...accrual,
      minimumPayPerShift: employee.minimum_pay_per_shift,
      percentOfCompletedOrders: employee.percent_of_completed_orders,
      sumMinimumAndPercent: employee.sum_minimum_and_percent,
    };
  });
});

const payrollColumns = [
  {
    label: 'Имя',
    field: (row) => {
      return row.employee.title ?? formatPhone(row.employee.phone);
    },
  },
  {
    label: 'Условия',
    template: 'terms',
  },
  {
    label: 'Выполнено работ',
    field: (row) => {
      return formatMoney(row.totalOfCompletedOrders);
    },
  },
  {
    label: 'Начислено',
    field: (row) => {
      return formatMoney(row.amountOfCompletedOrders);
    },
  },
  {
    label: 'Доплаты',
    field: (row) => {
      return formatMoney(row.surcharge);
    },
  },
  {
    label: 'Итого',
    field: (row) => {
      return formatMoney(row.total);
    },
  },
];

const confirmCashboxAmount = ref(false);
const queryClient = useQueryClient();
const loading = ref(false);

const approveCloseShift = async () => {
  loading.value = true;

  try {
    const shift = await closeShift();

    await queryClient.invalidateQueries({ queryKey: [queryKeys.shift] });

    await router.push({ name: 'shift.show', params: { shiftId: shift.data?.data?.shiftId } });
  } catch (error) {
    if (error?.response?.data?.message) {
      useToast().error(error.response.data.message);
    }

    loading.value = false;
  }
};

const tabsWithCurrent = computed(() => {
  if (!shift.value) {
    return tabs;
  }

  return [
    ...tabs,
    {
      title: 'Закрытие смены',
      route: {
        name: 'shift.close',
        params: {
          shiftId: shift.value.shiftId,
        },
      },
    },
  ];
});
</script>

<template>
  <CabinetLayout :tabs="tabsWithCurrent" :loading="isLoading" hide-in-loading>
    <div class="flex gap-x-[16px] items-center">
      <span class="text-lg font-medium">Закрытие смены №{{ shift.numberId }}</span>
      <span class="text-base font-normal whitespace-nowrap">
        (от {{ DateTime.fromISO(shift.createdAt).toFormat('d MMMM yyyy H:mm') }})
      </span>
    </div>
    <div class="mt-[24px] flex flex-wrap gap-[12px]">
      <AppStatsCard class="flex gap-[38px] items-center border">
        <div class="card-cell">
          <div class="flex gap-[4px] items-center">
            <span class="text-lg">
              <span v-if="totalIncome > 0">+</span>
              {{ formatMoney(totalIncome) }}
            </span>
          </div>
          <div class="text-sm text-gray-primary">Выручка за смену</div>
        </div>
        <div v-for="account in accountsIncome" :key="account.accountId" class="card-cell">
          <div class="flex gap-[4px] items-center">
            <span class="text-lg">
              <span v-if="account.total > 0">+</span>
              {{ formatMoney(account.total) }}
            </span>
          </div>
          <div class="text-sm text-gray-primary">{{ account.title }}</div>
        </div>
      </AppStatsCard>

      <AppStatsCard class="flex gap-[38px] items-center border">
        <div class="card-cell">
          <div class="flex gap-[4px] items-center">
            <span class="text-lg text-red-notify">
              <span v-if="totalExpense > 0">−</span>
              {{ formatMoney(totalExpense) }}
            </span>
          </div>
          <div class="text-sm text-gray-primary">Расходы</div>
        </div>

        <div class="card-cell">
          <div class="flex gap-[4px] items-center">
            <span class="text-lg text-red-notify">
              <span v-if="totalAccruals > 0">−</span>
              {{ formatMoney(totalAccruals) }}
            </span>
          </div>
          <div class="text-sm text-gray-primary">Заработок сотрудников</div>
        </div>
      </AppStatsCard>
    </div>

    <div class="mt-[24px] text-lg">Расшифровка начисления зарплат</div>

    <AppTable class="mt-[24px]" :data="employeesWithAccruals" :columns="payrollColumns">
      <template #row-terms="{ raw: accrual }">
        <div class="flex flex-col gap-[2px]">
          <div>{{ accrual.percentOfCompletedOrders }}% от работ</div>
          <div v-if="accrual.minimumPayPerShift > 0" class="text-sm text-gray-primary">
            <span v-if="accrual.sumMinimumAndPercent">плюс</span>
            <span v-else>минимум</span>
            {{ formatMoney(accrual.minimumPayPerShift) }} за смену
          </div>
        </div>
      </template>
    </AppTable>

    <div class="grow min-h-[10px]"></div>

    <div class="flex flex-wrap justify-end items-center gap-x-[24px] gap-y-[10px]">
      <div class="flex gap-x-[11px] items-center">
        <AppCheckbox v-model="confirmCashboxAmount" data-test="confirmCashboxAmount" />
        <div class="flex gap-x-[8px] items-center">
          <span class="text-sm md:text-base">Подтверждаю сумму в кассе</span>
          <span class="text-lg text-violet-primary">{{ formatMoney(cashboxAccount.balance) }}</span>
        </div>
      </div>
      <div class="grow md:grow-0">
        <AppButton
          :disabled="loading || !confirmCashboxAmount"
          class="w-full md:w-auto"
          data-test="shiftCloseSubmit"
          :loading="loading"
          @click="approveCloseShift()"
        >
          Закрыть смену
        </AppButton>
      </div>
    </div>
  </CabinetLayout>
</template>

<style scoped>
.card-cell {
  @apply relative flex flex-col gap-[6px] [&:not(:last-child)]:after:h-[50%] [&:not(:last-child)]:after:w-[1px] [&:not(:last-child)]:after:absolute [&:not(:last-child)]:after:-right-[19px] [&:not(:last-child)]:after:top-[25%] [&:not(:last-child)]:after:bg-gray-primary [&:not(:last-child)]:after:bg-opacity-30;
}
</style>
