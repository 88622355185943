<script setup>
import { computed } from 'vue';

const props = defineProps({
  rowStart: {
    type: Number,
    required: true,
  },
  rowSpan: {
    type: Number,
    default: 1,
  },
  colStart: {
    type: Number,
    required: true,
  },
  colSpan: {
    type: Number,
    default: 1,
  },
});

const cellSize = computed(() => {
  const styleClass = {};

  if (props.rowSpan > 1) {
    styleClass['grid-row'] = 'span ' + props.rowSpan + '/span ' + props.rowSpan;
  }

  return {
    '--rowStart': props.rowStart,
    '--rowEnd': props.rowStart + props.rowSpan,
    '--colStart': props.colStart,
    '--colEnd': props.colStart + props.colSpan,
  };
});
</script>

<template>
  <div
    class="row-start-[var(--rowStart)] row-end-[var(--rowEnd)] col-start-[var(--colStart)] col-end-[var(--colEnd)]"
    :style="cellSize"
  >
    <slot />
  </div>
</template>
