<script setup lang="ts">
import { Chart } from 'vue-chartjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from 'chart.js';
import { computed } from 'vue';
import { DateTime } from 'luxon';
import { commonChartOptions, commonChartColors } from '@/modules/report/chart-config';
import { useAnalyticsClientsQuery } from '@/query/useAnalyticsQuery.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
);

ChartJS.defaults.font.family = 'Roboto';

const props = defineProps<{
  startDate: string;
  endDate: string;
}>();

const params = computed(() => ({
  start_date: DateTime.fromISO(props.startDate).toFormat('yyyy-MM-dd'),
  end_date: DateTime.fromISO(props.endDate).toFormat('yyyy-MM-dd'),
}));

const { data: statistics, isLoading, error } = useAnalyticsClientsQuery(params);

const chartData = computed(() => {
  if (isLoading.value || !statistics.value) return null;

  // Проверяем, что statistics.value является массивом
  if (!Array.isArray(statistics.value)) {
    return null;
  }

  // Группируем данные по неделям
  const weeklyData = statistics.value.reduce(
    (acc, item) => {
      const date = DateTime.fromISO(item.date);
      const weekStart = date.startOf('week').toISODate();

      if (!acc[weekStart]) {
        acc[weekStart] = {
          total_clients: 0,
          total_clients_new: 0,
          total_clients_returning: 0,
          total_clients_anonymous: 0,
        };
      }

      acc[weekStart].total_clients += item.total_clients;
      acc[weekStart].total_clients_new += item.total_clients_new;
      acc[weekStart].total_clients_returning += item.total_clients_returning;
      acc[weekStart].total_clients_anonymous += item.total_clients_anonymous;

      return acc;
    },
    {} as Record<
      string,
      {
        total_clients: number;
        total_clients_new: number;
        total_clients_returning: number;
        total_clients_anonymous: number;
      }
    >,
  );

  // Преобразуем сгруппированные данные в формат для графика
  const sortedWeeks = Object.keys(weeklyData).sort();
  const dates = sortedWeeks.map((date) => DateTime.fromISO(date).toFormat('dd.MM.yyyy'));

  const datasets = [
    {
      label: 'Постоянные клиенты',
      data: sortedWeeks.map((week) => weeklyData[week].total_clients_returning),
      backgroundColor: commonChartColors.yellow.base,
      borderRadius: 0,
      borderSkipped: false,
      hoverBackgroundColor: commonChartColors.yellow.hover,
      order: 1,
    },
    {
      label: 'Новые клиенты',
      data: sortedWeeks.map((week) => weeklyData[week].total_clients_new),
      backgroundColor: commonChartColors.green.base,
      borderRadius: 0,
      borderSkipped: false,
      hoverBackgroundColor: commonChartColors.green.hover,
      order: 2,
    },
    {
      label: 'Анонимные клиенты',
      data: sortedWeeks.map((week) => weeklyData[week].total_clients_anonymous),
      backgroundColor: commonChartColors.purple.base,
      borderRadius: {
        topLeft: 3,
        topRight: 3,
        bottomLeft: 0,
        bottomRight: 0,
      },
      borderSkipped: false,
      hoverBackgroundColor: commonChartColors.purple.hover,
      order: 3,
    },
  ];

  return {
    labels: dates,
    datasets,
  };
});

const chartOptions = {
  ...commonChartOptions,
  barPercentage: 0.85,
  categoryPercentage: 0.85,
  plugins: {
    ...commonChartOptions.plugins,
    title: {
      display: true,
      text: 'Динамика роста посещений клуба клиентами',
      font: {
        size: 16,
        weight: 500,
      },
      padding: {
        bottom: 25,
      },
    },
    tooltip: {
      ...commonChartOptions.plugins?.tooltip,
      callbacks: {
        title: (context) => {
          const weekStart = DateTime.fromFormat(context[0].label, 'dd.MM.yyyy');
          const weekEnd = weekStart.plus({ days: 6 });
          return `Неделя: ${weekStart.toFormat('dd.MM.yyyy')} - ${weekEnd.toFormat('dd.MM.yyyy')}`;
        },
        label: (context) => {
          const value = context.parsed.y;
          if (value === null || value === undefined) return '';
          return `${context.dataset.label}: ${value.toLocaleString('ru-RU')} посещений`;
        },
        beforeBody: () => '───────────',
      },
    },
  },
  scales: {
    ...commonChartOptions.scales,
    y: {
      ...commonChartOptions.scales?.y,
      stacked: true,
      title: {
        display: true,
        text: 'Количество посещений',
        color: '#666',
        font: {
          size: 12,
        },
      },
    },
    x: {
      ...commonChartOptions.scales?.x,
      stacked: true,
      ticks: {
        ...commonChartOptions.scales?.x?.ticks,
        callback: function (val, index) {
          return index % 4 === 0 ? this.getLabelForValue(val) : '';
        },
      },
    },
  },
};
</script>

<template>
  <div class="chart-container">
    <div v-if="error" class="error-message">Произошла ошибка при загрузке данных</div>
    <div v-else-if="isLoading" class="loading-message">Загрузка данных...</div>
    <Chart v-else-if="chartData" type="bar" :data="chartData" :options="chartOptions" />
    <div v-else class="error-message">Нет данных для отображения</div>
  </div>
</template>

<style scoped>
.chart-container {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
}

.loading-message {
  color: #666;
  font-size: 14px;
}
</style>
