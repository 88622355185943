<script setup>
import TheVersionChecker from '@/components/TheVersionChecker.vue';
import { useQueryClient } from '@tanstack/vue-query';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/AuthStore';

const router = useRouter();
const authStore = useAuthStore();

// Глобальные настройки для vue-query
const queryClient = useQueryClient();

queryClient.setDefaultOptions({
  queries: {
    refetchInterval: 1000 * 60 * 10, // обновление всех данных раз в 10 минут
    enabled: true,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    select: (response) => response.data,
  },
});

router.beforeEach(async (to) => {
  const isAuthorized = await authStore.isAuthorized();

  const authRequired = to.meta?.requiresAuth !== false;
  const onlyGuest = to.meta?.onlyGuest === true;


  if (authRequired && !isAuthorized && to.name !== 'auth.login') {
    return { name: 'auth.login' };
  }

  if (onlyGuest && isAuthorized) {
    return { name: 'home.index' };
  }

  const hasCurrentBusiness = !!authStore.currentBusiness;
  const isCurrentPageBusiness = (to.name && to.name.indexOf('auth')) !== -1;

  if (!hasCurrentBusiness && !isCurrentPageBusiness) {
    return { name: 'auth.business-switch' };
  }
});

/*global __APP_BUILD_TIME__*/
const appBuildTime = computed(() => {
  return __APP_BUILD_TIME__;
});
</script>

<template>
  <div>
    <TheVersionChecker />

    <Suspense>
      <router-view />
    </Suspense>

    <div class="hidden mt-4 ml-4 text-xs text-gray-400">Last builded at: {{ appBuildTime }}</div>
  </div>
</template>
