import { DefaultResponse } from '@/interfaces/Response';
import http from '@/libs/http';
import { Business, StoreBusinessQueryParams, UpdateBusinessQueryParams } from '@/interfaces/Business';

export const getCurrentBusiness = (): Promise<DefaultResponse<Business>> => {
  return http.get('/api/v2/business/current');
};

export const getBusinesses = (): Promise<DefaultResponse<Business[]>> => {
  return http.get('/api/v2/businesses');
};

export const storeBusiness = (params: StoreBusinessQueryParams) => {
  return http.post('/api/v2/business', params);
};

export const assignEmployee = ({ phone, roleId }) => {
  return http.post('/api/v2/business/assign-employee', {
    phone,
    roleId,
  });
};

export const updateBusiness = (params: UpdateBusinessQueryParams) => {
  return http.put('/api/v2/business', params);
};

export const uploadBusinessPhoto = (photo: Blob) => {
  const formData = new FormData();
  formData.append('photo', photo);

  return http.post('/api/v2/business/photo', formData);
};
