<script setup>
import AppModal from '@/components/AppModal.vue';
import { ref } from 'vue';
import AppButton from '@/components/AppButton.vue';
import { unfireEmployee } from '@/api/employee';
import { useQueryClient } from '@tanstack/vue-query';
import { useToast } from '@/use/useToast.js';

const props = defineProps({
  employeeId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['close']);

const loading = ref(false);
const queryClient = useQueryClient();

const submit = async () => {
  try {
    loading.value = true;

    await unfireEmployee(props.employeeId);

    useToast().show('Сотрудник восстановлен');

    emit('close');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  } finally {
    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['employee'].indexOf(key) !== -1),
    });
    loading.value = false;
  }
};
</script>

<template>
  <AppModal :show="true" class="md:w-[638px]" @close="emit('close')">
    <template #title>Восстановить</template>

    <div>Вы уверены, что хотите восстановить уволенного сотрудника?</div>

    <template #actions>
      <div class="flex flex-col gap-[32px]">
        <div class="grid grid-cols-2 gap-x-[12px]">
          <AppButton outline :disabled="loading" @click="emit('close')">Отмена</AppButton>
          <AppButton red outline :loading="loading" :disabled="loading" @click="submit()">Восстановить</AppButton>
        </div>
      </div>
    </template>
  </AppModal>
</template>
