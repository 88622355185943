<script setup>
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { tabs } from '@/modules/shift/shift-tabs.js';
import { useShiftsQuery } from '@/query/useShiftQuery.js';
import AppTable from '@/components/AppTable.vue';
import { computed, ref } from 'vue';
import AppPagination from '@/components/AppPagination.vue';
import { DateTime } from 'luxon';
import { useEmployee } from '@/use/useEmployee';
import { formatMoney } from '@/use/useFormat.js';
import { useRouter } from 'vue-router';

const page = ref(1);
const perPage = ref(30);
const include = ['expenses', 'employees', 'orders'];
const { data, isLoading } = useShiftsQuery({ page, perPage, include });

const shifts = computed(() => {
  return data?.value?.data;
});

const total = computed(() => {
  return data?.value?.meta?.total | 1;
});

const { employeeShortTitle } = useEmployee();

const columns = [
  {
    label: '№',
    template: 'number',
  },
  {
    label: 'Выручка за смену',
    field: (shift) => {
      const totalIncome = shift.orders?.reduce((total, order) => {
        return total + order.price;
      }, 0);

      return formatMoney(totalIncome);
    },
  },
  {
    label: 'Расходы',
    field: (shift) => {
      const totalExpense = shift.expenses?.reduce((total, expense) => {
        return total + expense.amount;
      }, 0);

      return formatMoney(totalExpense);
    },
  },
  {
    label: 'На смене',
    template: 'employees',
  },
  {
    label: 'Закрыта',
    field: (shift) => {
      return shift.finishedAt ? DateTime.fromISO(shift.finishedAt).toFormat('d MMMM yyyy H:mm') : null;
    },
  },
];

const router = useRouter();

const goToShift = (shift) => {
  router.push({ name: 'shift.show', params: { shiftId: shift.shiftId } });
};
</script>

<template>
  <CabinetLayout :tabs="tabs" :loading="isLoading" :hide-in-loading="true">
    <AppTable :data="shifts" :columns="columns" sticky-headers row-class="cursor-pointer" @row-clicked="goToShift">
      <template #row-number="{ raw: shift }">
        <div class="flex flex-col gap-[4px]">
          <div class="whitespace-nowrap">Смена №{{ shift.numberId }}</div>
          <div class="text-xs text-gray-primary">
            от {{ DateTime.fromISO(shift.createdAt).toFormat('d MMMM yyyy') }}
          </div>
        </div>
      </template>

      <template #row-employees="{ raw: shift }">
        <div class="flex gap-[8px]">
          <div v-for="employee in shift.employees" :key="employee.employeeId">
            {{ employeeShortTitle(employee) }}
          </div>
        </div>
      </template>
    </AppTable>

    <div class="flex justify-end mt-[44px]">
      <AppPagination v-model="page" :total="total" :per-page="perPage" />
    </div>
  </CabinetLayout>
</template>
