import StorageIndexPage from '@/modules/storage/pages/StorageIndexPage.vue';
import StorageShowPage from '@/modules/storage/pages/StorageShowPage.vue';
import StoragePrintPage from '@/modules/storage/pages/StoragePrintPage.vue';
import StorageStatus from '@/enums/StorageStatus.js';

export default [
  {
    path: '/storage/',
    name: 'storage.index',
    redirect: () => {
      return { name: 'storage.index.in-stock' };
    },
    meta: { permissions: ['storage.view'] },
  },
  {
    path: '/storage/in-stock',
    props: () => ({ status: StorageStatus.IN_STOCK }),
    name: 'storage.index.in-stock',
    component: StorageIndexPage,
    meta: { permissions: ['storage.view'] },
  },
  {
    path: '/storage/released',
    props: () => ({ status: StorageStatus.RELEASED }),
    name: 'storage.index.released',
    component: StorageIndexPage,
    meta: { permissions: ['storage.update'] },
  },
  {
    path: '/storage/:storageId',
    name: 'storage.show',
    props(route) {
      const props = { ...route.params };
      props.storageId = +props.storageId;
      return props;
    },
    component: StorageShowPage,
    meta: { permissions: ['storage.view'] },
  },
  {
    path: '/storage/:storageId/print/:templateName',
    name: 'storage.print',
    props(route) {
      const props = { ...route.params };
      props.storageId = +props.storageId;
      props.temaplateName = props.templateName;
      return props;
    },
    component: StoragePrintPage,
    meta: {
      sidebar: false,
      permissions: ['storage.view'],
    },
  },
];
