import ReportServicesPage from '@/modules/report/pages/ReportServicesPage.vue';
import ReportClientsPage from '@/modules/report/pages/ReportClientsPage.vue';
import ReportGeneralPage from '@/modules/report/pages/ReportGeneralPage.vue';
export default [
  {
    path: '/report/general',
    name: 'report.general',
    redirect: '/report/clients',
    component: ReportGeneralPage,
    meta: {
      permissions: ['analytics.view'],
    },
  },
  {
    path: '/report/services',
    name: 'report.services',
    component: ReportServicesPage,
    meta: { permissions: ['analytics.view'] },
  },
  {
    path: '/report/clients',
    name: 'report.clients',
    component: ReportClientsPage,
    meta: { permissions: ['analytics.view'] },
  },
];
