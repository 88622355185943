<script setup>
import AppIcon from '@/components/AppIcon.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import IconLogo from '@/components/Icons/IconLogo.vue';
import IconTelegram from '@/components/Icons/IconTelegram.vue';
import AppButton from '@/components/AppButton.vue';
import { useRouter } from 'vue-router';
import { useTelegramConnectionLinkQuery } from '@/query/useTelegramQuery.js';
import { useAuthQuery } from '@/query/useAuthQuery.js';
import { watch } from 'vue';

const { data: connectionLink, isLoading } = useTelegramConnectionLinkQuery({
  select: (response) => response?.data?.data,
});

const { data: auth } = useAuthQuery({ refetchInterval: 1000, select: (response) => response?.data?.data });

const router = useRouter();

watch(
  auth,
  () => {
    if (auth.value?.employee?.telegramId) {
      router.push({ name: 'home.index' });
    }
  },
  { deep: true },
);
</script>

<template>
  <AuthLayout v-if="connectionLink" class="flex flex-col items-center">
    <div class="mt-[68px]">
      <AppIcon :icon="IconLogo" :width="69" :height="69" class="text-[#EC4899]" />
    </div>
    <div class="mt-[100px] md:mt-[124px] lg:mt-[146px]">
      <AppIcon :icon="IconTelegram" :width="67" :height="67" />
    </div>
    <div class="mt-[24px] text-2xl font-medium">Не открылся Telegram?</div>
    <div class="mt-[32px] flex items-center">
      <div class="w-[220px] h-[220px] border border-[#CBD3E4] rounded-[6px] p-1">
        <img :src="connectionLink.qrCodeImage" />
      </div>
    </div>
    <div class="mt-[32px] text-gray-primary text-center leading-[140%]">
      Отсканируйте QR-код с устройства на котором установлен Telegram-клиент
    </div>
    <div class="mt-[32px] flex gap-[12px]">
      <AppButton outline @click="router.push({ name: 'auth.connect-telegram' })">Назад</AppButton>
    </div>
  </AuthLayout>
</template>
