import { ChartOptions } from 'chart.js';

export const commonChartColors = {
  green: {
    base: 'rgba(76, 175, 80, 0.85)',
    hover: 'rgba(76, 175, 80, 1)',
  },
  yellow: {
    base: 'rgba(255, 193, 7, 0.85)',
    hover: 'rgba(255, 193, 7, 1)',
  },
  purple: {
    base: 'rgba(156, 39, 176, 0.85)',
    hover: 'rgba(156, 39, 176, 1)',
  },
};

export const commonChartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'top',
      align: 'end',
      labels: {
        boxWidth: 12,
        boxHeight: 12,
        padding: 15,
        color: '#666',
        usePointStyle: true,
      },
    },
    tooltip: {
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      titleColor: '#333',
      bodyColor: '#666',
      padding: 12,
      cornerRadius: 8,
      boxPadding: 6,
      borderColor: 'rgba(0, 0, 0, 0.1)',
      borderWidth: 1,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        padding: 8,
        color: '#666',
      },
      grid: {
        display: false,
      },
    },
    x: {
      ticks: {
        padding: 8,
        color: '#666',
        maxRotation: 0,
        minRotation: 0,
      },
      grid: {
        display: false,
      },
    },
  },
  animation: {
    duration: 750,
    easing: 'easeInOutQuart',
  },
  interaction: {
    intersect: false,
    mode: 'nearest',
  },
};
