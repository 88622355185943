<script setup lang="ts">
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { tabs } from '@/modules/report/report-tabs.js';
import { ref } from 'vue';
import { DateTime } from 'luxon';
import ClientsVisitsChart from '@/modules/report/components/ClientsVisitsChart.vue';
import DateRangeSelector from '@/modules/report/components/DateRangeSelector.vue';

const dates = ref({
  start_date: DateTime.now().minus({ days: 365 }).toISO(), // По умолчанию год
  end_date: DateTime.now().toISO(),
});
</script>

<template>
  <CabinetLayout :tabs="tabs">
    <div class="analytics-container">
      <DateRangeSelector v-model:start-date="dates.start_date" v-model:end-date="dates.end_date" />
      <ClientsVisitsChart :start-date="dates.start_date" :end-date="dates.end_date" />
    </div>
  </CabinetLayout>
</template>

<style scoped>
.analytics-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
