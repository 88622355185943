import SettingsEmployeesPage from '@/modules/settings/pages/SettingsEmployeesPage.vue';
import SettingsMainPage from '@/modules/settings/pages/SettingsMainPage.vue';
import SettingsBookingPage from '@/modules/settings/pages/SettingsBookingPage.vue';
import SettingsServicesPage from '@/modules/settings/pages/SettingsServicesPage.vue';
import SettingsClientsPage from '@/modules/settings/pages/SettingsClientsPage.vue';
import SettingsAccountsPage from '@/modules/settings/pages/SettingsAccountsPage.vue';
import SettingsEmployeesNewPage from '@/modules/settings/pages/SettingsEmployeesNewPage.vue';
import SettingsStoragePage from '@/modules/settings/pages/SettingsStoragePage.vue';
import SettingsBookingCustomFieldsPage from '@/modules/settings/pages/SettingsBookingCustomFieldsPage.vue';
import SettingsRolesPage from '@/modules/settings/pages/SettingsRolesPage.vue';

export default [
  {
    path: '/settings/main',
    name: 'settings.main.index',
    component: SettingsMainPage,
    meta: {
      permissions: ['settings.general'],
    },
  },
  {
    path: '/settings/employees',
    name: 'settings.employees.index',
    component: SettingsEmployeesPage,
    meta: { permissions: ['settings.employees'] },
  },
  {
    path: '/settings/employees/new',
    name: 'settings.employees.new',
    component: SettingsEmployeesNewPage,
    meta: { permissions: ['settings.employees'] },
  },
  {
    path: '/settings/booking',
    name: 'settings.booking.index',
    component: SettingsBookingPage,
    meta: { permissions: ['settings.booking'] },
  },
  {
    path: '/settings/booking/custom-fields',
    name: 'settings.booking.custom-fields.index',
    component: SettingsBookingCustomFieldsPage,
    meta: { permissions: ['settings.booking'] },
  },
  {
    path: '/settings/services',
    name: 'settings.services.index',
    component: SettingsServicesPage,
    meta: { permissions: ['settings.service'] },
  },
  {
    path: '/settings/clients',
    name: 'settings.clients.index',
    component: SettingsClientsPage,
    meta: { permissions: ['settings.client'] },
  },
  {
    path: '/settings/accounts',
    name: 'settings.accounts.index',
    component: SettingsAccountsPage,
    meta: { permissions: ['settings.account'] },
  },
  {
    path: '/settings/storage',
    name: 'settings.storage.index',
    component: SettingsStoragePage,
    meta: { permissions: ['settings.storage'] },
  },
  {
    path: '/settings/roles',
    name: 'settings.roles.index',
    component: SettingsRolesPage,
    meta: { permissions: ['settings.role'] },
  },
];
