import { ClientSourceQueryParams } from "@/interfaces/ClientSource";
import { useQuery } from "@tanstack/vue-query";
import { queryKeys } from "./queryKeys";
import { getClientSources } from "@/api/client_source";

export const useClientSourceQuery = ({ }: ClientSourceQueryParams = {}) =>
	useQuery({
		queryKey: [queryKeys.clientSources],
		queryFn: () => getClientSources(),
		select: (response) => response.data.data,
	});