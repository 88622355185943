import { computed, unref } from 'vue';
import { DateTime } from 'luxon';
import { useAuthStore } from '@/store/AuthStore';

export const useWorkingTime = (selectedDate, bookedIntervals = null) => {
  const authStore = useAuthStore();
  const currentBusiness = authStore.currentBusiness;

  bookedIntervals = unref(bookedIntervals);

  const minimumBookedHour = computed(() => {
    if (!bookedIntervals) {
      return null;
    }

    const hours = bookedIntervals.map((interval) => Math.ceil(interval.timeStart.split(':')[0]));
    return Math.min(...hours);
  });

  const maximumBookedHour = computed(() => {
    if (!bookedIntervals) {
      return null;
    }

    const hours = bookedIntervals.map((interval) => Math.ceil(interval.timeStart.split(':')[0]));
    return Math.max(...hours);
  });

  const openTime = computed(() => {
    let [hour, minute] = currentBusiness.open_time.split(':');

    if (hour > minimumBookedHour.value) {
      hour = minimumBookedHour.value;
    }

    return DateTime.fromISO(unref(selectedDate))
      .set({
        hour,
        minute,
        seconds: 0,
        millisecond: 0,
      })
      .toISO();
  });

  const closeTime = computed(() => {
    let [hour, minute] = currentBusiness.close_time.split(':');

    if (hour !== '00' && hour < maximumBookedHour.value) {
      hour = maximumBookedHour.value;
    }

    let closeTime = DateTime.fromISO(unref(selectedDate))
      .set({
        hour,
        minute,
        seconds: 0,
        millisecond: 0,
      })
      .toISO();

    if (DateTime.fromISO(openTime.value) >= DateTime.fromISO(closeTime)) {
      closeTime = DateTime.fromISO(closeTime).plus({ day: 1 }).toISO();
    }

    return closeTime;
  });

  const workingHours = computed(() => {
    const openTimeObject = DateTime.fromISO(openTime.value);
    const closeTimeObject = DateTime.fromISO(closeTime.value);

    const hours = [];

    for (let cursorTime = openTimeObject; cursorTime < closeTimeObject; cursorTime = cursorTime.plus({ hour: 1 })) {
      hours.push(cursorTime.toISO());
    }

    return hours;
  });

  return {
    openTime,
    closeTime,
    workingHours,
  };
};
