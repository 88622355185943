import { DateTime } from 'luxon';
import { formatPhone } from '@/use/useFormat.js';

export const clientsColumns = [
  {
    label: 'Имя',
    field: (client) => {
      return client.title;
    },
  },
  {
    label: 'Телефон',
    field: (client) => {
      return formatPhone(client.phone);
    },
  },
  {
    label: 'Дата регистрации',
    field: (client) => {
      return DateTime.fromISO(client.createdAt).toFormat('d.LL.y');
    },
  },
  {
    label: 'Записей',
    field: (client) => {
      return client.bookingsCount;
    },
  },
  {
    label: 'Последняя запись',
    field: (client) => {
      return client.lastBookedAt ? DateTime.fromISO(client.lastBookedAt).toFormat('d.LL.y') : '-';
    },
  },
  {
    label: 'Источник',
    field: (client) => {
      return client.source ? client.source.title : '-';
    },
  },
];
