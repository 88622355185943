export const tabs = [
  {
    title: 'Основные',
    route: {
      name: 'settings.main.index',
    },
  },
  {
    title: 'Бронирование',
    route: {
      name: 'settings.booking.index',
    },
  },
  {
    title: 'Сотрудники',
    route: {
      name: 'settings.employees.index',
    },
  },
  {
    title: 'Права доступа',
    route: {
      name: 'settings.roles.index',
    },
  },
  {
    title: 'Услуги',
    route: {
      name: 'settings.services.index',
    },
  },
  {
    title: 'Клиенты',
    route: {
      name: 'settings.clients.index',
    },
  },
  {
    title: 'Хранение',
    route: {
      name: 'settings.storage.index',
    },
  },
  {
    title: 'Счета',
    route: {
      name: 'settings.accounts.index',
    },
  },
];
