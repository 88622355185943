import http from '@/libs/http.js';
import { DefaultResponse } from '@/interfaces/Response';
import { AnalyticsClients, AnalyticsClientsQueryParams } from '@/interfaces/Analytics';

export const getAnalyticsGeneral = (startTime, endTime, groupBy) =>
  http.get('/api/v2/analytics/general', {
    params: {
      startTime,
      endTime,
      groupBy,
    },
  });

export const getAnalyticsClients = (
  params: AnalyticsClientsQueryParams,
): Promise<DefaultResponse<AnalyticsClients[]>> =>
  http.get('/api/v2/analytics/clients', {
    params,
  });
