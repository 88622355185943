import BusinessSphere from '@/enums/BusinessSphere.js';
import IconClock from '@/components/Icons/IconClock.vue';
import IconPlus from '@/components/Icons/IconPlus.vue';
import IconFile from '@/components/Icons/IconFile.vue';
import IconFileEdit from '@/components/Icons/IconFileEdit.vue';
import IconBox from '@/components/Icons/IconBox.vue';
import IconEmployees from '@/components/Icons/IconEmployees.vue';
import IconFileCheck from '@/components/Icons/IconFileCheck.vue';
import IconCoinHand from '@/components/Icons/IconCoinHand.vue';
import IconGear from '@/components/Icons/IconGear.vue';

export const menu = [
  {
    label: 'Смена',
    name: 'shift.show',
    url: '/shift/current',
    icon: IconClock,
    sphere: [BusinessSphere.TIRE_FITTING],
  },
  {
    label: 'Заказы',
    name: 'order.new',
    url: '/order/new',
    icon: IconFile,
    sphere: [BusinessSphere.TIRE_FITTING],
  },
  {
    label: 'Запись',
    name: 'booking.current',
    url: '/booking',
    icon: IconFileEdit,
    sphere: [BusinessSphere.TIRE_FITTING, BusinessSphere.SIMRACE_CLUB],
  },
  {
    label: 'Хранение',
    name: 'storage.index',
    url: '/storage',
    icon: IconBox,
    sphere: [BusinessSphere.TIRE_FITTING],
  },
  {
    label: 'Клиенты',
    name: 'client.index',
    url: '/client',
    icon: IconEmployees,
    sphere: [BusinessSphere.TIRE_FITTING, BusinessSphere.SIMRACE_CLUB],
  },
  {
    label: 'Финансы',
    name: 'operation.index',
    url: '/operation',
    icon: IconCoinHand,
    sphere: [BusinessSphere.TIRE_FITTING, BusinessSphere.SIMRACE_CLUB],
  },
  {
    label: 'Отчеты',
    name: 'report.general',
    url: '/report/general',
    icon: IconFileCheck,
    sphere: [BusinessSphere.TIRE_FITTING],
  },
  {
    label: 'Настройки',
    name: 'settings.main.index',
    url: '/settings/main',
    icon: IconGear,
    sphere: [BusinessSphere.TIRE_FITTING, BusinessSphere.SIMRACE_CLUB],
  },
];
