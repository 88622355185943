<script setup>
import { computed, ref } from 'vue';
import { useOrderQuery } from '@/query/useOrderQuery.js';
import { useAuthStore } from '@/store/AuthStore';
import { formatMoney, formatPhone } from '@/use/useFormat.js';
import { DateTime } from 'luxon';
import AppTable from '@/components/AppTable.vue';
import AppPrintTemplate from '@/components/AppPrintTemplate.vue';
import OrderStatus from '@/enums/OrderStatus.js';

const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
});

const include = [
  'works.service',
  'client',
  'car.mark',
  'car.model',
  'payments.account',
  'storage',
  'employees',
  'shift',
];
const { data } = useOrderQuery({ orderId: props.orderId, include });
const order = computed(() => {
  return data.value?.data;
});

const template = ref(null);
const parsedTemplate = computed(() => {
  return template.value?.innerHTML;
});

const { currentBusiness } = useAuthStore();

const columns = [
  {
    field: (work) => {
      return work.service.title;
    },
    label: 'Цена',
  },
  {
    field: (work) => {
      return formatMoney(work.price);
    },
    label: 'Наименование',
  },
  {
    field: 'count',
    label: 'Количество',
  },
  {
    field: (work) => {
      return formatMoney(work.price * work.count);
    },
    label: 'Всего',
  },
];

const orderPaymentsAccounts = computed(() => {
  const groupedPayments = {};
  order.value?.payments.forEach((payment) => {
    if (!groupedPayments[payment.account.accountId]) {
      groupedPayments[payment.account.accountId] = {
        ...payment.account,
        total: 0,
      };
    }

    groupedPayments[payment.account.accountId].total += payment.amount;
  });

  return Object.values(groupedPayments);
});

const title = computed(() => {
  switch (order.value.status) {
    case OrderStatus.COMPLETED:
      return 'Заказ';

    default:
      return 'Предварительный заказ';
  }
});
</script>

<template>
  <div v-if="order">
    <div ref="template" class="hidden">
      <div class="text-xl text-gray-primary">{{ currentBusiness.title }}</div>
      <div class="text-sm mt-[32px] flex flex-col gap-y-[3px]">
        <div>{{ currentBusiness.city }}</div>
        <div>{{ currentBusiness.address }}</div>
      </div>
      <div class="text-sm mt-[24px] flex flex-col gap-y-[3px]">
        <div v-if="order.client">{{ order.client.title }} {{ formatPhone(order.client.phone) }}</div>
        <div v-else class="text-gray-primary">
          Клиент не указан
        </div>

        <div v-if="order.car">
          {{ order.car.title }} <span class="text-gray-primary">{{ order.car.regNumber }}</span>
        </div>
        <div v-else class="text-gray-primary">
          Автомобиль не указан
        </div>
      </div>

      <div class="mt-[40px]">
        <div class="font-medium text-sm">
          {{ title }} №{{ order.numberId }} от {{ DateTime.fromISO(order.createdAt).toFormat('d MMMM yyyy') }}
        </div>
      </div>

      <AppTable col-class="!text-2xs" row-class="text-xs" :data="order.works" :columns="columns" class="mt-[32px]">
      </AppTable>

      <div class="mt-[24px] grid grid-cols-[250px_1fr] gap-y-[8px] text-xs">
        <div>Итого без скидки</div>
        <div>{{ formatMoney(order.prePrice) }}</div>
        <div>Скидка</div>
        <div>{{ formatMoney(order.discount) }}</div>
      </div>

      <div class="mt-[24px] flex gap-x-[8px]">
        <div class="font-medium">Итого: {{ formatMoney(order.price) }}</div>
        <div class="text-gray-primary text-sm">
          <div v-if="orderPaymentsAccounts && orderPaymentsAccounts.length > 1">
            (<span v-for="(account, index) in orderPaymentsAccounts" :key="account.accountId">
              <span v-if="index > 0">, </span>{{ account.title }}
              <span class="text-gray-primary">{{ formatMoney(account.total) }}</span> </span>)
          </div>
          <div v-if="orderPaymentsAccounts?.length">({{ orderPaymentsAccounts[0].title }})</div>
        </div>
      </div>

      <div class="mt-[24px] h-[1px] w-full bg-[#D9D9D9]"></div>

      <ul class="mt-[24px] list-disc text-xs px-[15px] text-gray-primary">
        <li>Гарантия на выполненные работы составляет 3 дня</li>
        <li>Гарантия на покраску дисков 5 лет (вздутие, отслоение лакокрасочного покрытия)</li>
        <li>Рекомендуем проверить момент затяжки гаек/болтов колесных дисков через 50-70 км</li>
      </ul>
    </div>

    <AppPrintTemplate v-if="parsedTemplate" :text="parsedTemplate" />
  </div>
</template>
