import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { unref } from 'vue';
import { getAnalyticsGeneral, getAnalyticsClients } from '@/api/analytics.js';
import { queryKeys } from './queryKeys';

export const useAnalyticsGeneralQuery = ({ startTime, endTime, groupBy }, options = {}) => {
  const queryKey = [queryKeys.analyticsGeneral, startTime, endTime, groupBy];

  if (options.keepPreviousData) {
    options.placeholderData = keepPreviousData;
    delete options.keepPreviousData;
  }

  return useQuery({
    queryKey,
    queryFn: () => {
      return getAnalyticsGeneral(unref(startTime), unref(endTime), unref(groupBy));
    },
    ...options,
  });
};

export const useAnalyticsClientsQuery = (params, options = {}) => {
  const queryKey = [queryKeys.analyticsClients, params];

  return useQuery({
    queryKey,
    queryFn: () => {
      return getAnalyticsClients(unref(params));
    },
    select: (response) => response.data.data,
    ...options,
  });
};
