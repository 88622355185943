<script setup>
import TheLoading from '@/components/TheLoading.vue';
import { computed, useSlots, withCtx } from 'vue';
import TheLeftSidebar from '@/components/TheLeftSidebar.vue';
import { useSidebarStore } from '@/store/SidebarStore.js';
import { useAuthStore } from '@/store/AuthStore';
import AppIcon from '@/components/AppIcon.vue';
import IconLocation from '@/components/Icons/IconLocation.vue';
import { useRouter } from 'vue-router';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  hide: {
    type: Boolean,
    default: false,
  },
  hideInLoading: {
    type: Boolean,
    default: false,
  },
  tabs: {
    type: Array,
    default: new Array(),
  },
});

const router = useRouter();
const { tryPermissionByRouteName } = useAuthStore();
const { hasAccess, isAllowGranted } = useAuthStore();

const isHideContent = computed(() => {
  return props.hide || (props.loading && props.hideInLoading);
});

const slots = useSlots();
const sidebarStore = useSidebarStore();
const authStore = useAuthStore();

const hasSeveralBusinesses = computed(() => {
  return authStore.employee.businesses?.length > 1;
});

const permissions = computed(() => {
  return router.currentRoute.value.meta.permissions;
});

</script>

<template>
  <div class="flex gap-x-[20px]">
    <TheLoading :loading="props.loading" />

    <TheLeftSidebar />

    <main v-if="isAllowGranted(permissions)"
      class="grow md:flex mb-[61px] md:mb-0 md:min-h-screen md:flex-col mx-[16px] md:mx-0 md:pt-[23px] md:mr-[20px]">
      <div v-if="props.tabs.length > 0"
        class="sticky top-0 bg-white z-10 flex items-center justify-between mb-[23px] border-b border-[#E8F0F4]">
        <div class="text-sm md:text-base flex flex-wrap items-center gap-[6px] md:gap-[12px] overflow-y-auto">
          <div v-for="tab in tabs" v-show="tryPermissionByRouteName(tab.route?.name)" :key="tab.route"
            class="px-[6px] md:px-[12px] py-[10px] flex items-center justify-center gap-[12px] hover:text-[#828282] whitespace-nowrap">
            <router-link :to="tab.route" active-class="text-violet-primary hover:!text-violet-primary font-medium">
              <div>{{ tab.title }}</div>
            </router-link>
          </div>
        </div>

        <router-link :to="{ name: 'auth.business-switch' }"
          class="flex gap-x-[6px] items-center hover:text-violet-primary cursor-pointer">
          <AppIcon :icon="IconLocation" :width="18" :height="18" class="text-gray-primary" />

          <div>{{ authStore.currentBusiness.title }}</div>
          <!-- <div v-if="hasSeveralBusinesses">{{ authStore.currentBusiness.title }}</div> -->
        </router-link>
      </div>

      <div class="flex flex-col grow mb-[20px]">
        <slot v-if="!isHideContent" />
      </div>

      <div v-if="!!slots['footer-sticky']"
        class="sticky bottom-[61px] md:bottom-0 flex md:pb-0 -mx-[20px] mt-[20px] bg-white px-[16px] border-t border-[#E0E8F9] transition-spacing duration-150">
        <slot name="footer-sticky" />
      </div>
    </main>
  </div>
</template>
