<script setup>
import AppInput from '@/components/AppInput.vue';
import { computed, ref, watch } from 'vue';
import OrderEditPaymentAccount from '@/modules/order/components/OrderEditPaymentAccount.vue';

const props = defineProps({
  amountDebt: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['account-selected']);

const firstAccountAmount = ref(null);
const secondAccountAmount = ref(null);

const orderAmountDebt = computed(() => {
  return parseFloat(props.amountDebt / 100);
});

watch([firstAccountAmount, orderAmountDebt], () => {
  firstAccountAmount.value = parseFloat(firstAccountAmount.value);

  if (firstAccountAmount.value > orderAmountDebt.value) {
    firstAccountAmount.value = orderAmountDebt.value;
  }

  const amount = orderAmountDebt.value - firstAccountAmount.value;
  secondAccountAmount.value = amount > 0 ? amount : 0;
});

watch(secondAccountAmount, () => {
  secondAccountAmount.value = parseFloat(secondAccountAmount.value);

  if (secondAccountAmount.value > orderAmountDebt.value) {
    secondAccountAmount.value = orderAmountDebt.value;
  }

  const amount = orderAmountDebt.value - parseFloat(secondAccountAmount.value);
  firstAccountAmount.value = amount > 0 ? amount : 0;
});

const firstAccountId = ref(null);
const secondAccountId = ref(null);

watch([firstAccountId, firstAccountAmount, secondAccountId, secondAccountAmount], () => {
  emit('account-selected', {
    accountId: firstAccountId.value,
    secondAccountId: secondAccountId.value,
    secondAccountAmount: secondAccountAmount.value * 100,
  });
});
</script>

<template>
  <div class="grid grid-cols-2 gap-[24px]">
    <div class="flex flex-col gap-y-[12px]">
      <div>Сумма</div>
      <div>
        <AppInput v-model="firstAccountAmount" data-test="payment-account-amount-first" required />
      </div>
    </div>

    <OrderEditPaymentAccount
      v-model="firstAccountId"
      :exclude-account-id="secondAccountId"
      data-test="payment-account-first"
    />

    <div class="flex flex-col gap-y-[12px]">
      <div>Сумма</div>
      <div>
        <AppInput v-model="secondAccountAmount" data-test="payment-account-amount-secondary" required />
      </div>
    </div>

    <OrderEditPaymentAccount
      v-model="secondAccountId"
      :exclude-account-id="firstAccountId"
      data-test="payment-account-secondary"
    />
  </div>
</template>
