<script setup>
import { computed, ref } from 'vue';
import OrderEditServicesStorageNew from '@/modules/order/components/OrderEditServicesStorageNew.vue';
import AppButton from '@/components/AppButton.vue';
import OrderEditServicesStorageList from '@/modules/order/components/OrderEditServicesStorageList.vue';
import { useStorageQuery } from '@/query/useStorageQuery.js';
import { useAuthStore } from '@/store/AuthStore';

const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
  serviceId: {
    type: Number,
    required: true,
  },
  carClass: {
    type: String,
    required: true,
  },
  wheelDiameter: {
    type: Number,
    required: true,
  },
});

const { data: storage } = useStorageQuery({ orderId: props.orderId });

const storageItems = computed(() => {
  return storage.value?.data?.items ?? [];
});

const showStorageAdd = ref(false);
</script>

<template>
  <div class="flex flex-col gap-y-[32px]">
    <OrderEditServicesStorageList v-if="!showStorageAdd" :order-id="props.orderId" />

    <div v-if="!showStorageAdd && storageItems.length === 0">
      <AppButton black outline @click="showStorageAdd = true">Добавить хранение</AppButton>
    </div>

    <div v-if="!showStorageAdd && storageItems.length > 0" class="flex justify-end gap-x-[12px]">
      <AppButton @click="showStorageAdd = true">Новое хранение</AppButton>
      <AppButton outline>Печать документов</AppButton>
    </div>

    <OrderEditServicesStorageNew
      v-if="showStorageAdd"
      :order-id="props.orderId"
      :service-id="props.serviceId"
      :car-class="props.carClass"
      :wheel-diameter="props.wheelDiameter"
      @cancel="showStorageAdd = false"
      @created="showStorageAdd = false"
    />
  </div>
</template>
