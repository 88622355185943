<script setup>
import AppModal from '@/components/AppModal.vue';
import { computed, ref } from 'vue';
import AppSelectButtons from '@/components/AppSelectButtons.vue';
import OrderEditPaymentDiscount from '@/modules/order/components/OrderEditPaymentDiscount.vue';
import AppButton from '@/components/AppButton.vue';
import OrderEditPaymentFull from '@/modules/order/components/OrderEditPaymentFull.vue';
import OrderEditPaymentPartial from '@/modules/order/components/OrderEditPaymentPartial.vue';
import useVuelidate from '@vuelidate/core';
import { orderPaymentStore } from '@/api/order.js';
import { useToast } from '@/use/useToast.js';
import { useQueryClient } from '@tanstack/vue-query';
import OrderEditPaymentList from '@/modules/order/components/OrderEditPaymentList.vue';
import { useAuthStore } from '@/store/AuthStore';

const props = defineProps({
  order: {
    type: Object,
    required: true,
  },
  isOrderPaid: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['closed']);

const close = () => {
  emit('closed');
};

const isShow = ref(true);

const payTypes = ref([
  {
    label: 'Полная оплата',
    value: 'fullPayment',
  },
  {
    label: 'Частичная оплата',
    value: 'partialPayment',
  },
]);

const payType = ref('fullPayment');

const accountId = ref(null);
const secondAccountId = ref(null);
const secondAccountAmount = ref(null);

const accountSelect = ({ accountId: id, secondAccountId: secondId, secondAccountAmount: secondAmount }) => {
  accountId.value = id;
  secondAccountId.value = secondId;
  secondAccountAmount.value = secondAmount;
};

const payTypeSelect = (type) => {
  payType.value = type;
  accountId.value = null;
  secondAccountId.value = null;
  secondAccountAmount.value = null;
};

const vuelidate = await useVuelidate();
const loading = ref(false);
const queryClient = useQueryClient();

const paymentAccept = async () => {
  if (!(await vuelidate.value.$validate())) {
    return false;
  }

  try {
    loading.value = true;

    await orderPaymentStore(props.order.orderId, {
      accountId: accountId.value,
      secondAccountId: secondAccountId.value,
      secondAccountAmount: secondAccountAmount.value,
    });

    close();
  } catch (error) {
    useToast().error(error?.response?.data?.message);
  } finally {
    loading.value = false;

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['order'].indexOf(key) !== -1),
    });
  }
};
</script>

<template>
  <AppModal
    :show="isShow"
    class="md:!w-[584px]"
    @close="close"
  >
    <template #title>Оплата заказа</template>
    <div class="flex flex-col gap-y-[32px]">
      <template v-if="!props.isOrderPaid">
        <div class="flex flex-col gap-y-[24px]">
          <AppSelectButtons
            :model-value="payType"
            :options="payTypes"
            class="!grid !grid-cols-2"
            @changed="payTypeSelect"
          />

          <OrderEditPaymentFull
            v-if="payType === 'fullPayment'"
            data-test="payment-full"
            @account-selected="accountSelect"
          />

          <OrderEditPaymentPartial
            v-if="payType === 'partialPayment'"
            :amount-debt="props.order.amountDebt"
            @account-selected="accountSelect"
          />

          <OrderEditPaymentDiscount :order="props.order" />
        </div>
      </template>

      <template v-if="order.discountPercent === 100 && hasAccess('add-discount-Order')">
        <OrderEditPaymentDiscount :order="props.order" />
      </template>

      <OrderEditPaymentList
        v-if="order.payments && order.payments.length > 0"
        :payments="order.payments"
        :is-order-paid="props.isOrderPaid"
      />
    </div>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton
          outline
          class="w-full last:col-span-2"
          data-test="payment-close"
          @click="close()"
        >
          Закрыть
        </AppButton>
        <AppButton
          v-if="props.order.discountPercent < 100"
          class="w-full"
          :disabled="loading"
          :loading="loading"
          data-test="payment-submit"
          @click="paymentAccept()"
          >Принять оплату
        </AppButton>
      </div>
    </template>
  </AppModal>
</template>
