<script setup>
import AppModal from '@/components/AppModal.vue';
import { useToast } from '@/use/useToast.js';
import { computed, ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { useQueryClient } from '@tanstack/vue-query';
import AppButton from '@/components/AppButton.vue';
import AppInput from '@/components/AppInput.vue';
import useVuelidate from '@vuelidate/core';
import { serviceCategoryStore, serviceCategoryUpdate } from '@/api/service_category.js';
import { useServiceCategoryQuery } from '@/query/useServiceCategoryQuery.js';

const props = defineProps({
  categoryId: {
    type: Number,
    required: false,
    default: null,
  },
});

const emit = defineEmits(['closed', 'saved']);

const isShow = ref(true);
const router = useRouter();
const queryClient = useQueryClient();
const loading = ref(false);

const close = () => {
  emit('closed');
};

const vuelidate = useVuelidate();

const save = async () => {
  if (!(await vuelidate.value.$validate())) {
    return;
  }

  loading.value = true;

  try {
    if (props.categoryId) {
      await serviceCategoryUpdate(props.categoryId, {
        title: title.value,
      });
    } else {
      await serviceCategoryStore({ title: title.value });
    }

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => ['service', 'service-category'].indexOf(key) !== -1),
    });
    useToast().show('Данные сохранены');
    emit('saved');
  } catch (error) {
    loading.value = false;
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку'
      );
    } else {
      throw error;
    }
  }
};

const modalTitle = computed(() => {
  return props.categoryId ? 'Редактирование категории' : 'Новая категория';
});

const title = ref(null);

const enabled = computed(() => {
  return !!props.categoryId;
});

const { data: category, isLoading } = useServiceCategoryQuery(
  { categoryId: props.categoryId },
  { enabled, select: (response) => response?.data?.data }
);

watchEffect(() => {
  if (!category.value) {
    return;
  }

  title.value = category.value.title;
});
</script>

<template>
  <AppModal :show="isShow" :loading="props.categoryId && isLoading" @close="close()">
    <template #title>{{ modalTitle }}</template>
    <div class="flex flex-col gap-y-[24px]">
      <div class="flex flex-col gap-y-[12px]">
        <div>Название категории</div>
        <div>
          <AppInput v-model="title" required />
        </div>
      </div>
    </div>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton outline :disabled="loading" @click="close()">Отмена</AppButton>
        <AppButton :disabled="loading" :loading="loading" @click="save()">Сохранить</AppButton>
      </div>
    </template>
  </AppModal>
</template>
