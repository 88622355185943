<script setup>
import { onMounted } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { useRouter } from 'vue-router';
import AuthLayout from '@/layouts/AuthLayout.vue';
import { logout } from '@/api/auth.js';
import { useQueryClient } from '@tanstack/vue-query';
const authStore = useAuthStore();

const router = useRouter();
const queryClient = useQueryClient();

onMounted(async () => {
  try {
    await logout();
  } finally {
    await authStore.resetAuth();
    await authStore.resetBusiness();
    await queryClient.resetQueries();
    await router.push({ name: 'auth.login' });
  }
});
</script>

<template>
  <AuthLayout></AuthLayout>
</template>
