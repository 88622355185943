<script setup>
import AppSelect from '@/components/AppSelect.vue';
import { useEmployeesQuery } from '@/query/useEmployeeQuery.js';
import EmployeeStatus from '@/enums/EmployeeStatus.js';
import { computed, ref } from 'vue';
import { orderEmployeesSync } from '@/api/order.js';
import { useToast } from '@/use/useToast.js';
import { useQueryClient } from '@tanstack/vue-query';
import { useEmployee } from '@/use/useEmployee';

const props = defineProps({
  orderId: {
    type: Number,
    required: true,
  },
  selectedEmployees: {
    type: Array,
    required: true,
  },
});

const { data } = useEmployeesQuery({
  assignedAsExecutor: true,
  statusId: EmployeeStatus.WORKING,
});

const { employeeShortTitle } = useEmployee();

const employees = computed(() => {
  return data?.value?.data?.map((employee) => {
    return {
      ...employee,
      shortTitle: employeeShortTitle(employee),
    };
  });
});

const queryClient = useQueryClient();
const loading = ref(false);

const selectedEmployees = computed({
  get() {
    return props.selectedEmployees;
  },
  async set(newValue) {
    loading.value = true;

    console.log({ newValue });

    try {
      await orderEmployeesSync({ orderId: props.orderId, employeesId: newValue });
    } catch (error) {
      useToast().error(error?.response?.data?.message);
    } finally {
      await queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.some((key) => ['order'].indexOf(key) !== -1),
      });

      loading.value = false;
    }
  },
});
</script>

<template>
  <div class="flex items-center gap-x-[10px]">
    <div>Исполнитель</div>
    <div>
      <AppSelect
        v-if="employees"
        v-model="selectedEmployees"
        data-test="order-employees-list"
        :saving="loading"
        :disabled="loading"
        class="w-[280px]"
        multiple
        option-value="employee_id"
        option-label="shortTitle"
        :options="employees"
        direction="top"
        required
      />
    </div>
  </div>
</template>
