<script setup>
import { computed } from 'vue';
import { useUtils } from '@/use/useUtils.js';

const props = defineProps({
  modelValue: {
    required: true,
    type: [Array, Boolean, String, Number],
  },

  trueValue: {
    type: [Array, Boolean, String, Number],
    default: true,
  },

  falseValue: {
    type: [Array, Boolean, String, Number],
    default: false,
  },

  label: {
    type: [Boolean, String, Number],
    default: false,
  },

  noWrapLabel: {
    type: Boolean,
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'changed']);

const isMultiple = computed(() => {
  return Array.isArray(props.modelValue);
});

const isChecked = computed(() => {
  return isMultiple.value ? props.modelValue.indexOf(props.trueValue) !== -1 : props.modelValue === props.trueValue;
});

const toggle = () => {
  isChecked.value ? uncheck() : check();
};

const check = () => {
  let newValue;

  if (isMultiple.value) {
    newValue = props.modelValue;
    newValue.push(props.trueValue);
  } else {
    newValue = props.trueValue;
  }

  emit('update:modelValue', newValue);
  emit('changed');
};

const uncheck = () => {
  let newValue;

  if (isMultiple.value) {
    newValue = props.modelValue;
    const index = newValue.indexOf(props.trueValue);

    if (index > -1) {
      newValue.splice(index, 1);
    }
  } else {
    newValue = props.falseValue;
  }

  emit('update:modelValue', newValue);
  emit('changed');
};

const checkboxId = useUtils().uniqueId();
</script>

<template>
  <div class="flex items-center gap-x-[11px]">
    <input
      :disabled="props.disabled"
      :id="checkboxId"
      type="checkbox"
      :checked="isChecked"
      class="h-5 w-5 select-none rounded border-indigo-200 text-indigo-500 shadow-sm transition duration-100 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 disabled:opacity-50"
      @click="toggle"
    />

    <label
      v-if="props.label"
      class="select-none"
      :class="[{ 'md:whitespace-nowrap': !props.noWrapLabel }, { 'md:whitespace': props.noWrapLabel }]"
      :for="checkboxId"
      >{{ props.label }}</label
    >
  </div>
</template>
