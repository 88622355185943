import { Permission } from '@/interfaces/Permission';
import { DefaultResponse } from '@/interfaces/Response';
import { Role } from '@/interfaces/Role';
import http from '@/libs/http.js';


export const getRoles = (): Promise<DefaultResponse<Role[]>> => {
  return http.get('/api/v2/role', {});
};

export const getPermissions = (): Promise<DefaultResponse<Permission[]>> => {
  return http.get('/api/v2/permission', {});
};

export const roleStore = ({ title, permissions }) => {
  return http.post('/api/v2/role', {
    title,
    permissions,
  });
};

export const roleUpdate = (roleId, { title, permissions }) => {
  return http.put(`/api/v2/role/${roleId}`, {
    title,
    permissions,
  });
};

export const roleDelete = (roleId) => {
  return http.delete('/api/v2/role/' + roleId);
};

export const roleUsersUpdate = ({ roleId, userId }) => {
  return http.post('/api/v2/role/users', {
    roleId,
    userId,
  });
};

export const roleUsersDelete = (roleId, userId) => {
  return http.delete(`/api/v2/role/${roleId}/users/${userId}`);
};
