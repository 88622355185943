import BookingIndexPage from '@/modules/booking/pages/BookingIndexPage.vue';
import { DateTime } from 'luxon';
export default [
  {
    path: '/booking/:date?',
    name: 'booking.index',
    component: BookingIndexPage,
    props: true,
    meta: { permissions: ['booking.view'] },
  },
  {
    path: '/booking/',
    name: 'booking.current',
    redirect: () => {
      return { name: 'booking.index', params: { date: DateTime.now().toISODate() } };
    },
    meta: { permissions: ['booking.view'] },
  },
];
