import AuthLoginPage from '@/modules/auth/pages/AuthLoginPage.vue';
import AuthLogoutPage from '@/modules/auth/pages/AuthLogoutPage.vue';
import AuthBusinessSwitchPage from '@/modules/auth/pages/AuthBusinessSwitchPage.vue';
import AuthBusinessCreatePage from '@/modules/auth/pages/AuthBusinessCreatePage.vue';
import AuthSetNamePage from '@/modules/auth/pages/AuthSetNamePage.vue';
import AuthConnectTelegramPage from '@/modules/auth/pages/AuthConnectTelegramPage.vue';
import AuthConnectTelegramLinkPage from '@/modules/auth/pages/AuthConnectTelegramLinkPage.vue';

export default [
  {
    path: '/auth/login',
    name: 'auth.login',
    component: AuthLoginPage,
    meta: {
      sidebar: false,
      requiresAuth: false,
      onlyGuest: true,
    },
  },
  {
    path: '/auth/logout',
    name: 'auth.logout',
    component: AuthLogoutPage,
    meta: {
      sidebar: false,
      requiresAuth: true,
    },
  },
  {
    path: '/auth/business-switch',
    name: 'auth.business-switch',
    component: AuthBusinessSwitchPage,
    meta: {
      sidebar: false,
      requiresAuth: true,
      permissions: ['view-any-Business'],
    },
  },
  {
    path: '/auth/business-create',
    name: 'auth.business-create',
    component: AuthBusinessCreatePage,
    meta: {
      sidebar: false,
      requiresAuth: true,
      //permissions: ['create-Business'],
    },
  },
  {
    path: '/auth/set-name',
    name: 'auth.set-name',
    component: AuthSetNamePage,
    meta: {
      sidebar: false,
      requiresAuth: true,
    },
  },
  {
    path: '/auth/connect/telegram',
    name: 'auth.connect-telegram',
    component: AuthConnectTelegramPage,
    meta: {
      sidebar: false,
      requiresAuth: true,
    },
  },
  {
    path: '/auth/connect/telegram/link',
    name: 'auth.connect-telegram-link',
    component: AuthConnectTelegramLinkPage,
    meta: {
      sidebar: false,
      requiresAuth: true,
    },
  },
];
