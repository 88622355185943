<script setup>
import { useShiftQuery } from '@/query/useShiftQuery.js';
import { computed, ref, watch } from 'vue';
import AppModal from '@/components/AppModal.vue';
import { useEmployee } from '@/use/useEmployee';
import { useEmployeesQuery } from '@/query/useEmployeeQuery.js';
import AppCheckbox from '@/components/AppCheckbox.vue';
import AppButton from '@/components/AppButton.vue';
import { shiftEmployeesSync } from '@/api/shift.js';
import { useQueryClient } from '@tanstack/vue-query';
import EmployeeStatus from '@/enums/EmployeeStatus.js';

const props = defineProps({
  shiftId: {
    type: [String, Number],
    required: true,
  },
});

const emit = defineEmits(['updated', 'cancel']);
const include = ['employees'];

const { data: shiftData, isLoading: isShiftLoading } = useShiftQuery({ shiftId: props.shiftId, include });

const shift = computed(() => {
  return shiftData.value?.data;
});

const { data: employeesData, isLoading: isEmployeesLoading } = useEmployeesQuery({
  assignedAsExecutor: true,
  statusId: EmployeeStatus.WORKING,
});

const availableEmployees = computed(() => {
  return employeesData?.value?.data;
});

const selectedEmployees = ref(null);

const employees = computed({
  get() {
    if (selectedEmployees.value) {
      return selectedEmployees.value;
    }

    if (!shift.value.employees) {
      return [];
    }

    return shift.value.employees.map((employee) => employee.employeeId);
  },

  set(newValue) {
    selectedEmployees.value = newValue;
  },
});

const loading = ref(false);
const errors = ref(null);
const queryClient = useQueryClient();

const save = async () => {
  loading.value = true;

  try {
    await shiftEmployeesSync(shift.value.shiftId, { employeesId: selectedEmployees.value });

    await queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => key === 'employees'),
    });

    selectedEmployees.value = null;
    emit('updated');
  } catch (error) {
    errors.value = error.response?.data;
  } finally {
    loading.value = false;
  }
};

watch(
  () => selectedEmployees,
  () => {
    if (!selectedEmployees.value) {
      return;
    }

    errors.value = null;
  },
  {
    deep: true,
  }
);

const isModalShow = ref(true);
const { employeeShortTitle } = useEmployee();

watch(isModalShow, (newValue) => {
  if (!newValue) {
    emit('cancel');
  }
});
</script>

<template>
  <AppModal
    v-model:show="isModalShow"
    :loading="isShiftLoading || isEmployeesLoading"
  >
    <template #title>Сейчас в смене</template>
    <div class="grid grid-cols-2 gap-y-[12px] gap-x-[24px]">
      <div
        v-for="employee in availableEmployees"
        :key="employee.employeeId"
        class="px-2"
      >
        <AppCheckbox
          v-model="employees"
          :true-value="employee.employeeId"
          :label="employeeShortTitle({ ...employee })"
        />
      </div>

      <div
        v-if="errors"
        class="mt-3 text-rose-500"
      >
        {{ errors?.message }}
      </div>
    </div>
    <template #actions>
      <div class="flex gap-x-[12px]">
        <AppButton
          outline
          :disabled="loading"
          class="w-full"
          @click="emit('cancel')"
          >Отмена</AppButton
        >

        <AppButton
          :disabled="!selectedEmployees || loading"
          :loading="loading"
          class="w-full"
          @click="save"
          >Сохранить
        </AppButton>
      </div>
    </template>
  </AppModal>
</template>
