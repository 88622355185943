import http from '@/libs/http.js';

export const getOrders = ({ page, perPage, status, shiftId, include } = {}) => {
  return http.get('/api/v2/order', {
    params: {
      page,
      perPage,
      status,
      shiftId,
      include,
    },
  });
};

export const getOrder = ({ orderId, withTrashed, include }) => {
  return http.get('/api/v2/order/' + orderId, { params: { withTrashed, include } });
};

export const storeOrder = ({ clientId, firstName, lastName, phone, carId, markId, modelId, regNumber }) => {
  return http.post('/api/v2/order', {
    clientId,
    firstName,
    lastName,
    phone,
    carId,
    markId,
    modelId,
    regNumber,
  });
};

export const updateOrder = ({
  orderId,
  status,
  carClass,
  wheelDiameter,
  prePrice,
  price,
  discountPercent,
  works,
  accountId,
  employees,
}) => {
  return http.put('/api/v1/order/' + orderId, {
    status,
    carClass,
    wheelDiameter,
    prePrice,
    price,
    discountPercent,
    works,
    accountId,
    employees,
  });
};

export const deleteOrder = (orderId) => {
  return http.delete('/api/v1/order/' + orderId);
};

export const updateOrderClient = ({
  orderId,
  clientId,
  firstName,
  lastName,
  phone,
  carId,
  markId,
  modelId,
  regNumber,
}) =>
  http.put('/api/v1/order/' + orderId + '/client', {
    clientId,
    firstName,
    lastName,
    phone,
    carId,
    markId,
    modelId,
    regNumber,
  });

export const orderWorkAdd = ({ orderId, serviceId, count, title, price, carClass, wheelDiameter }) =>
  http.post('/api/v2/order/work-add', {
    orderId,
    serviceId,
    count,
    title,
    price,
    carClass,
    wheelDiameter,
  });

export const orderWorkRemove = (workId) => {
  return http.delete('/api/v2/order/work', {
    params: {
      workId,
    },
  });
};

export const orderDiscountUpdate = ({ orderId, discount }) => {
  return http.put('/api/v2/order/discount', {
    orderId,
    discount,
  });
};

export const orderEmployeesSync = ({ orderId, employeesId }) => {
  return http.put('/api/v2/order/employees', {
    orderId,
    employeesId,
  });
};

export const orderStatusUpdate = ({ orderId, status }) => {
  return http.put('/api/v2/order/status', {
    orderId,
    status,
  });
};

export const orderClientAndCarChange = (
  orderId,
  { clientId, firstName, lastName, phone, carId, markId, modelId, regNumber },
) => {
  return http.put('/api/v2/order/client-and-car-change', {
    orderId,
    clientId,
    firstName,
    lastName,
    phone,
    carId,
    markId,
    modelId,
    regNumber,
  });
};

export const orderCarClassUpdate = (orderId, carClass) => {
  return http.put('/api/v2/order/car-class', {
    orderId,
    carClass,
  });
};

export const orderWheelDiameterUpdate = (orderId, wheelDiameter) => {
  return http.put('/api/v2/order/wheel-diameter', {
    orderId,
    wheelDiameter,
  });
};

export const orderAccountUpdate = (orderId, accountId) => {
  return http.put('/api/v2/order/account', {
    orderId,
    accountId,
  });
};

export const orderPaymentStore = (orderId, { accountId, secondAccountId, secondAccountAmount }) => {
  return http.post('/api/v2/order/payment', {
    orderId,
    accountId,
    secondAccountId,
    secondAccountAmount,
  });
};

export const orderPaymentRemove = (paymentId) => {
  return http.delete('/api/v2/order/payment', {
    params: {
      paymentId,
    },
  });
};

export const orderComplete = (orderId) => {
  return http.put('/api/v2/order/complete', {
    orderId,
  });
};

export const orderRemove = (orderId) => {
  return http.delete('/api/v2/order', {
    params: {
      orderId,
    },
  });
};
