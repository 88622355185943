<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import AppModal from '@/components/AppModal.vue';
import AppCheckbox from '@/components/AppCheckbox.vue';
import AppScrollable from '@/components/AppScrollable.vue';
import AppButton from '@/components/AppButton.vue';
import AppInput from '@/components/AppInput.vue';
import AppField from '@/components/AppField.vue';
import { useToast } from '@/use/useToast.js';
import { roleStore, roleUpdate } from '@/api/role';
import { useRolesQuery } from '@/query/useRoleQuery';
import { usePermissionsQuery } from '@/query/usePermissionQuery';
import type { Permission } from '@/interfaces/Permission';
import { firstLetterToUpperCase } from '@/use/useFormat';
import { queryKeys } from '@/query/queryKeys';

interface Props {
  roleId?: number;
}

interface PermissionModule {
  name: string;
  permissions: Permission[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const isShow = ref(true);
const queryClient = useQueryClient();
const loading = ref(false);

const title = ref('');
const selectedPermissions = ref<number[]>([]);
const selectedModules = ref<string[]>([]);

const { data: roles } = useRolesQuery({
  enabled: !!props.roleId,
});

const currentRole = computed(() => roles.value?.find((role) => role.id === props.roleId));

watch(
  currentRole,
  (newVal) => {
    if (newVal) {
      title.value = newVal.title;
      selectedPermissions.value = newVal.permissions.map((p) => p.id);
    }
  },
  { immediate: true },
);

const { data: permissions } = usePermissionsQuery();

const modules = computed((): PermissionModule[] => {
  if (!permissions.value) return [];

  // Получаем уникальные модули
  const uniqueModules = Array.from(new Set(permissions.value.map((p) => p.module)));

  // Группируем разрешения по модулям
  return uniqueModules.map(
    (module): PermissionModule => ({
      name: module as string,
      permissions: permissions.value.filter((p) => p.module === module),
    }),
  );
});

const updateSelectedPermissionGroups = (moduleName: string, checked: boolean) => {
  const module = modules.value.find((m) => m.name === moduleName);
  if (!module) return;

  if (checked) {
    const newPermissions = [...selectedPermissions.value, ...module.permissions.map((p) => p.id)];
    selectedPermissions.value = Array.from(new Set(newPermissions));
    selectedModules.value = [...selectedModules.value, moduleName];
  } else {
    selectedPermissions.value = selectedPermissions.value.filter((p) => !module.permissions.some((mp) => mp.id === p));
    selectedModules.value = selectedModules.value.filter((m) => m !== moduleName);
  }
};

const updateSelectedPermissions = (permissionId: number) => {
  const module = modules.value.find((m) => m.permissions.some((p) => p.id === permissionId));
  if (!module) return;

  const allModulePermissionsSelected = module.permissions.every((p) => selectedPermissions.value.includes(p.id));

  if (allModulePermissionsSelected) {
    selectedModules.value = [...selectedModules.value, module.name];
  } else {
    selectedModules.value = selectedModules.value.filter((m) => m !== module.name);
  }
};

const close = () => {
  emit('close');
};

const save = async () => {
  console.log('save');

  if (!(await vuelidate.value.$validate())) {
    console.log('error');
    return;
  }

  loading.value = true;

  try {
    if (props.roleId) {
      await roleUpdate(props.roleId, {
        title: title.value,
        permissions: selectedPermissions.value,
      });
    } else {
      await roleStore({
        title: title.value,
        permissions: selectedPermissions.value,
      });
    }

    queryClient.invalidateQueries({ queryKey: [queryKeys.roles] });

    useToast().show('Данные успешно сохранены');
    emit('close');
  } catch (error) {
    if (error.response) {
      useToast().error(
        error?.response?.data?.message ?? 'Неизвестная ошибка. Если ошибка повторяется, обратитесь в поддержку',
      );
    } else {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

const rules = computed(() => ({
  title: { required },
  selectedPermissions: { required },
}));

const vuelidate = useVuelidate(rules, {
  title,
  selectedPermissions,
});

const modalTitle = computed(() => {
  return currentRole.value ? 'Редактирование группы пользователей' : 'Добавление группы пользователей';
});

onMounted(() => {
  if (currentRole.value) {
    //Установка выбранных модулей разрешений
    modules.value.forEach((module) => {
      const allModulePermissionsSelected = module.permissions.every((p) => selectedPermissions.value.includes(p.id));

      if (allModulePermissionsSelected) {
        selectedModules.value.push(module.name);
      }
    });
  }
});
</script>

<template>
  <AppModal :show="isShow" width="large" @close="close">
    <template #title>{{ modalTitle }}</template>

    <AppField>
      <div class="text-base text-gray-900 mb-[12px]">
        Название группы
        <span class="text-red-500">*</span>
      </div>
      <AppInput v-model="title" required />
    </AppField>

    <div class="mt-[20px]">
      <div class="text-base text-gray-900 mb-[12px]">
        Права для пользователей группы
        <span class="text-red-500">*</span>
      </div>
      <div class="text-sm text-gray-primary">Необходимо выбрать хотя бы одно право доступа</div>
    </div>

    <AppScrollable class="mt-[24px] !h-[300px]">
      <ul>
        <li v-for="module in modules" :key="module.name" class="mb-[24px] ml-[5px]">
          <AppCheckbox
            v-model="selectedModules"
            class="text-lg"
            :label="firstLetterToUpperCase(module.name)"
            :true-value="module.name"
            :data-test="'permission-group-' + module.name"
            @change="updateSelectedPermissionGroups(module.name, $event.target.checked)"
          />
          <ul class="ml-[24px]">
            <li v-for="permission in module.permissions" :key="permission.name" class="mt-[12px]">
              <AppCheckbox
                v-model="selectedPermissions"
                :label="permission.description"
                :true-value="permission.id"
                no-wrap-label
                @change="updateSelectedPermissions(permission.id)"
              />
            </li>
          </ul>
        </li>
      </ul>
    </AppScrollable>

    <template #actions>
      <div class="grid grid-cols-2 gap-x-[12px]">
        <AppButton outline :disabled="loading" @click="close">Отмена</AppButton>
        <AppButton :disabled="loading" :loading="loading" @click="save">Сохранить</AppButton>
      </div>
    </template>
  </AppModal>
</template>
