<template>
  <div v-if="horizontal" class="mb-3">
    <div class="flex items-center">
      <div v-if="isShowLabel" class="mr-6 shrink grow basis-0 text-right font-bold text-gray-500">
        {{ label }}
      </div>
      <div class="shrink grow-[5] basis-0">
        <slot />
      </div>
    </div>
    <div v-if="$slots.footer" class="flex">
      <div class="mr-6 shrink grow basis-0"></div>
      <div class="shrink grow-[5] basis-0 text-gray-600">
        <slot name="footer" />
      </div>
    </div>
  </div>
  <div v-else class="mb-3">
    <div v-if="isShowLabel" class="mb-1 font-bold text-gray-500">
      {{ label }}
    </div>
    <div><slot /></div>
    <div v-if="$slots.footer"><slot name="footer" /></div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';

const props = defineProps<{
  label: string | number | null;
  horizontal: boolean;
  hideLabel: boolean;
  error?: string;
}>();

const isShowLabel = computed(() => !!props.label && !props.hideLabel);
</script>

<style scoped>
.form-field {
  @apply mb-4;
}
</style>
