export const queryKeys = {
  clientSources: 'client-sources',
  roles: 'roles',
  permissions: 'permissions',
  businesses: 'businesses',
  shift: 'shift',
  order: 'order',
  analyticsClients: 'analytics-clients',
  analyticsGeneral: 'analytics-general',
};
