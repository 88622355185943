<script setup lang="ts">
import { computed, nextTick, ref, watch, onMounted, onUnmounted, PropType } from 'vue';
import { onClickOutside } from '@vueuse/core';
import AppIcon from '@/components/AppIcon.vue';
import IconChevronDown from '@/components/Icons/IconChevronDown.vue';
import IconCheck from '@/components/Icons/IconCheck.vue';
import { required as requiredValidator } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import AppSpinner from '@/components/AppSpinner.vue';

interface SelectOption {
  [key: string]: any;
}

type SelectedValue = number | string | (number | string)[] | null;

const props = defineProps({
  modelValue: {
    type: [Number, String, Array] as PropType<SelectedValue>,
    default: null,
  },
  options: {
    type: Array as PropType<SelectOption[]>,
    required: true,
  },
  optionValue: {
    type: String,
    default: 'value',
  },
  optionLabel: {
    type: String,
    default: 'label',
  },
  placeholder: {
    type: String,
    default: 'Не выбран',
  },
  placeholderFocused: {
    type: String,
    default: 'Начните вводить ',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  direction: {
    type: String,
    default: 'bottom',
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  saving: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'search-query-changed', 'changed']);

const searchQuery = ref(null);
const searchInput = ref(null);

watch(searchQuery, () => {
  emit('search-query-changed', searchQuery.value);
});

const getOptionValue = (option) => {
  if (!option) return null;
  return option[props.optionValue];
};

const getOptionLabel = (option) => {
  if (!option) return '';
  return option[props.optionLabel];
};

const selected = ref<SelectedValue>(props.multiple ? [] : null);

watch(
  () => props.modelValue,
  (newValue) => {
    if (props.multiple && Array.isArray(newValue)) {
      selected.value = newValue;
    } else if (props.multiple && newValue) {
      if (Array.isArray(selected.value)) {
        selected.value.push(newValue as number | string);
      }
    } else {
      selected.value = newValue;
    }
  },
  { immediate: true },
);

const isFocused = ref(null);

const focus = () => {
  if (props.disabled) {
    return;
  }

  isFocused.value = true;

  if (props.searchable) {
    nextTick(() => {
      searchInput.value.focus();
    });
  }
};

watch(isFocused, () => {
  if (!isFocused.value) {
    nextTick(() => {
      searchQuery.value = null;
    });
  }
});

const cachedOptions = ref([]);

watch(
  () => props.options,
  (newOptions) => {
    if (newOptions) {
      newOptions.forEach((option) => {
        const value = getOptionValue(option);

        if (!cachedOptions.value.find((cached) => getOptionValue(cached) === value)) {
          cachedOptions.value.push(option);
        }
      });
    }
  },
  { immediate: true },
);

const cacheOption = (value) => {
  const cachedOption = cachedOptions.value.find((option) => {
    return getOptionValue(option) === value;
  });

  if (cachedOption) {
    return;
  }

  const option = props.options.find((opt) => getOptionValue(opt) === value);

  if (option) {
    cachedOptions.value.push(option);
  }
};

const select = (value: number | string) => {
  if (props.disabled) {
    return;
  }

  if (props.multiple && Array.isArray(selected.value) && !isValueSelected(value)) {
    selected.value.push(value);
  }

  if (!props.multiple) {
    selected.value = value;
  }

  cacheOption(value);
  emitSelectedValue();

  if (!props.multiple) {
    nextTick(() => {
      isFocused.value = false;
    });
  }
};

const unselect = (value: number | string) => {
  if (props.multiple && Array.isArray(selected.value)) {
    selected.value = selected.value.filter((selectedValue) => selectedValue !== value);
  } else {
    selected.value = null;
  }

  emitSelectedValue();

  if (!props.multiple) {
    isFocused.value = false;
  }
};

const hasSelected = computed(() => {
  if (props.multiple) {
    return Array.isArray(selected.value) && selected.value.length > 0;
  } else {
    return selected.value !== null && selected.value !== undefined;
  }
});

const isValueSelected = (value: number | string) => {
  if (!hasSelected.value) {
    return false;
  }

  if (props.multiple && Array.isArray(selected.value)) {
    return selected.value.indexOf(value) !== -1;
  } else {
    return selected.value === value;
  }
};

const toggle = (value) => {
  if (props.saving) {
    return;
  }

  if (isValueSelected(value)) {
    unselect(value);
  } else {
    select(value);
  }
};

const getOptions = (values) => {
  if (values instanceof Array) {
    return values.map((value) => {
      return getOption(value);
    });
  }

  return getOption(values);
};

const getOption = (value) => {
  if (!value) return null;

  const cachedOption = cachedOptions.value.find((option) => getOptionValue(option) === value);

  if (cachedOption) {
    return cachedOption;
  }

  const option = props.options.find((opt) => getOptionValue(opt) === value);

  if (option) {
    cachedOptions.value.push(option);
  }

  return option;
};

const emitSelectedValue = () => {
  emit('update:modelValue', selected.value);
  emit('changed', selected.value);
};

const getSelectedLabel = computed(() => {
  if (props.multiple && Array.isArray(selected.value)) {
    return selected.value
      .map((value) => {
        const option = getOption(value);
        return getOptionLabel(option);
      })
      .join(', ');
  }

  return getOptionLabel(getOption(selected.value));
});

const container = ref(null);
const dropdown = ref(null);

const dropdownPosition = ref('bottom');

const dropdownStyle = ref({
  top: '0px',
  left: '0px',
  width: '0px',
});

const updateDropdownPosition = () => {
  if (!container.value || !dropdown.value) return;

  const containerRect = container.value.getBoundingClientRect();
  const spaceAbove = containerRect.top;
  const spaceBelow = window.innerHeight - containerRect.bottom;

  dropdownPosition.value = spaceAbove > spaceBelow ? 'top' : 'bottom';

  dropdownStyle.value = {
    top:
      dropdownPosition.value === 'top'
        ? `${containerRect.top - dropdown.value.offsetHeight - 8}px`
        : `${containerRect.bottom + 8}px`,
    left: `${containerRect.left}px`,
    width: `${containerRect.width}px`,
  };
};

onMounted(() => {
  window.addEventListener('scroll', updateDropdownPosition);
  window.addEventListener('resize', updateDropdownPosition);
});

onUnmounted(() => {
  window.removeEventListener('scroll', updateDropdownPosition);
  window.removeEventListener('resize', updateDropdownPosition);
});

watch(isFocused, (newValue) => {
  if (newValue) {
    nextTick(() => {
      updateDropdownPosition();
    });
  }
});

onClickOutside(container, () => {
  isFocused.value = false;
});

const showErrorBorder = computed(() => {
  return vuelidate.value.$error;
});

const rules = {};

if (props.required) {
  rules['selected'] = {
    required: requiredValidator,
  };
}

const vuelidate = useVuelidate(rules, { selected });

// Добавляем методы для анимации
const onDropdownEnter = (el: Element) => {
  const options = el.querySelectorAll('.py-\\[12px\\]');

  options.forEach((option, index) => {
    (option as HTMLElement).style.setProperty('--option-index', index.toString());
  });
};

const onDropdownLeave = () => {
  // Можно добавить дополнительную логику при закрытии
};
</script>

<template>
  <div
    id="app-select"
    ref="container"
    class="relative select-none"
    :class="{ 'opacity-50': props.disabled }"
    v-bind="$attrs"
  >
    <div
      v-if="!isFocused || !props.searchable"
      class="flex items-center h-[42px] w-full px-[12px] border border-[#CBD3E4] text-black-primary rounded-[6px] bg-white shadow-sm"
      :class="{
        'cursor-text': props.searchable,
        'cursor-default': !props.searchable,
        '!cursor-not-allowed': props.disabled,
        '!border-rose-400': showErrorBorder,
      }"
      @click="focus()"
    >
      <span v-if="hasSelected">
        <slot name="selected" :selected="getOptions(selected)">
          {{ getSelectedLabel }}
        </slot>
      </span>
      <span v-else class="text-[#AEAEAE]">{{ props.placeholder }}</span>
    </div>

    <input
      v-if="isFocused && props.searchable"
      ref="searchInput"
      v-model="searchQuery"
      name="search"
      type="text"
      class="h-[42px] w-full border border-[#CBD3E4] focus:border-[#AEAEAE] text-black-primary placeholder-[#AEAEAE] rounded-[6px] outline-none bg-white shadow-sm transition duration-100 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
      :class="{ '!placeholder-black-primary': hasSelected && !isFocused }"
      :placeholder="placeholderFocused"
      @click="focus()"
    />

    <div
      v-if="props.loading"
      class="absolute right-[30px] inset-y-[2px] bg-white flex items-center justify-center w-[30px]"
      @click="focus()"
    >
      <AppSpinner class="w-[20px] h-[20px]" />
    </div>

    <div class="absolute right-[2px] inset-y-[2px] bg-white flex items-center justify-center w-[30px]" @click="focus()">
      <AppIcon class="" :width="10" :height="6" :icon="IconChevronDown" />
    </div>
  </div>

  <Teleport to="body">
    <Transition
      name="select-dropdown"
      @before-enter="updateDropdownPosition"
      @enter="onDropdownEnter"
      @leave="onDropdownLeave"
    >
      <div
        v-show="isFocused && !props.loading"
        ref="dropdown"
        class="fixed z-[999999] rounded-[6px] bg-white py-[18px] flex flex-col gap-y-[8px] border border-[#CBD3E4] shadow-xl max-h-60 overflow-y-auto scrollbar"
        :style="dropdownStyle"
      >
        <div v-if="props.options?.length > 0">
          <div
            v-for="option in props.options"
            :key="getOptionValue(option)"
            class="py-[12px] px-[30px] hover:bg-[#FAFBFE] cursor-pointer flex select-none gap-x-[5px]"
            :class="{
              '!text-[#626AE9]': isValueSelected(getOptionValue(option)),
              'cursor-wait': props.saving,
            }"
            data-test="option"
            :data-checked="isValueSelected(getOptionValue(option))"
            @click="toggle(getOptionValue(option))"
          >
            <div class="grow">
              <slot name="default" :option="option">
                {{ getOptionLabel(option) }}
              </slot>
            </div>
            <div class="self-center text-[#626AE9]">
              <AppIcon v-if="isValueSelected(getOptionValue(option))" :icon="IconCheck" :width="12" :height="11" />
            </div>
          </div>
        </div>
        <slot v-else name="notfound" :search-query="searchQuery">
          <div class="px-[10px] text-gray-primary">Ничего не найдено</div>
        </slot>

        <slot name="last" />
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped>
.scrollbar::-webkit-scrollbar {
  width: 16px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  @apply bg-gray-200;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-opacity-70;
}

/* Анимации для выпадающего списка */
.select-dropdown-enter-active,
.select-dropdown-leave-active {
  transition: all 0.2s ease-in-out;
}

.select-dropdown-enter-from,
.select-dropdown-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.select-dropdown-enter-to,
.select-dropdown-leave-from {
  opacity: 1;
  transform: translateY(0);
}

/* Анимация для опций */
.select-dropdown-enter-active .py-\[12px\] {
  transition: all 0.2s ease-in-out;
  transition-delay: calc(var(--option-index) * 0.05s);
}

.select-dropdown-enter-from .py-\[12px\] {
  opacity: 0;
  transform: translateX(-10px);
}

.select-dropdown-enter-to .py-\[12px\] {
  opacity: 1;
  transform: translateX(0);
}
</style>
