<script setup>
import { useEmployeesQuery } from '@/query/useEmployeeQuery.js';
import AppTable from '@/components/AppTable.vue';
import { computed, ref } from 'vue';
import AppSelectButtons from '@/components/AppSelectButtons.vue';
import EmployeeStatus from '@/enums/EmployeeStatus.js';
import AppButton from '@/components/AppButton.vue';
import { useRouter } from 'vue-router';
import { workingColumns, firedColumns } from '@/modules/settings/tables/employeesTable.js';
import AppIcon from '@/components/AppIcon.vue';
import IconPencil from '@/components/Icons/IconPencil.vue';
import IconStats from '@/components/Icons/IconStats.vue';
import IconArrowRevert from '@/components/Icons/IconArrowRevert.vue';
import SettingsEmployeesEdit from '@/modules/settings/components/SettingsEmployeesEdit.vue';
import { tabs } from '@/modules/settings/settings-tabs.js';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import SettingsEmployeesFire from '@/modules/settings/components/SettingsEmployeesFire.vue';
import { DateTime } from 'luxon';
import SettingsEmployeesUnfire from '@/modules/settings/components/SettingsEmployeesUnfire.vue';
import { useRolesQuery } from '@/query/useRoleQuery';

const filterTabs = [
  {
    label: 'В штате',
    value: EmployeeStatus.WORKING,
  },
  {
    label: 'Уволенные',
    value: EmployeeStatus.FIRED,
  },
];

const statusId = ref(EmployeeStatus.WORKING);

const options = { keepPreviousData: true };
const { data, isLoading, isFetching } = useEmployeesQuery({ statusId }, options);
const employees = computed(() => data.value?.data);

const router = useRouter();

const columns = computed(() => {
  if (statusId.value === EmployeeStatus.WORKING) {
    return workingColumns;
  } else {
    return firedColumns;
  }
});

const editEmployeeId = ref(null);
const fireEmployeeId = ref(null);
const unfireEmployeeId = ref(null);

const fire = (employeeId) => {
  editEmployeeId.value = null;
  fireEmployeeId.value = employeeId;
};

const cancelFire = (employeeId) => {
  fireEmployeeId.value = null;
  editEmployeeId.value = employeeId;
};

const { data: roles } = useRolesQuery();

const getRoleTitle = (roleName) => {
  return roles.value?.find((role) => role.name === roleName)?.title;
};
</script>

<template>
  <CabinetLayout
    :tabs="tabs"
    :loading="isLoading || isFetching"
    :hide-in-loading="isLoading === true"
  >
    <div class="flex">
      <AppSelectButtons
        v-model="statusId"
        :options="filterTabs"
      />
    </div>

    <div>
      <AppTable
        v-if="employees"
        class="mt-[24px] text-sm"
        :data="employees"
        :columns="columns"
        row-data-test="employee-list-item"
      >
        <template #row-fired="{ raw: employee }">
          <div class="flex flex-col">
            <span v-if="employee.fired_at">
              {{ DateTime.fromISO(employee.fired_at).toFormat('d.LL.y') }}
            </span>
            <span class="text-gray-primary">{{ employee.fired_reason }}</span>
          </div>
        </template>

        <template #row-role="{ raw: employee }">
          <div class="flex gap-x-[12px] items-center">
            <span>{{ getRoleTitle(employee.role_id) }}</span>
          </div>
        </template>

        <template #row-actions-working="{ raw: employee }">
          <div class="flex gap-x-[12px] items-center">
            <AppIcon
              :icon="IconPencil"
              data-test="employee-edit"
              class="cursor-pointer hover:text-violet-primary"
              @click="editEmployeeId = employee.employee_id"
            />
            <!-- @todo сделать статистику -->
            <div class="hidden w-[1px] h-[16px] bg-[#D9D9D9]"></div>
            <AppIcon
              :icon="IconStats"
              class="hidden cursor-pointer hover:text-violet-primary"
            />
          </div>
        </template>

        <template #row-actions-fired="{ raw: employee }">
          <div class="flex gap-x-[12px] items-center">
            <AppIcon
              :icon="IconArrowRevert"
              class="cursor-pointer hover:text-violet-primary"
              @click="unfireEmployeeId = employee.employee_id"
            />
            <!-- @todo сделать статистику -->
            <div class="hidden w-[1px] h-[16px] bg-[#D9D9D9]"></div>
            <AppIcon
              :icon="IconStats"
              class="hidden cursor-pointer hover:text-violet-primary"
            />
          </div>
        </template>
      </AppTable>
    </div>

    <SettingsEmployeesEdit
      v-if="editEmployeeId"
      :employee-id="editEmployeeId"
      @close="editEmployeeId = null"
      @fire="(employeeId) => fire(employeeId)"
    />

    <SettingsEmployeesFire
      v-if="fireEmployeeId"
      :employee-id="fireEmployeeId"
      @close="fireEmployeeId = null"
      @cancel="(employeeId) => cancelFire(employeeId)"
    />

    <SettingsEmployeesUnfire
      v-if="unfireEmployeeId"
      :employee-id="unfireEmployeeId"
      @close="unfireEmployeeId = null"
    />

    <div class="flex">
      <AppButton
        outline
        class="mt-[24px]"
        @click="router.push({ name: 'settings.employees.new' })"
        >Новый сотрудник
      </AppButton>
    </div>
  </CabinetLayout>
</template>
