<script setup>
import { useOperationsQuery } from '@/query/useOperationQuery.js';
import { computed, ref } from 'vue';
import StorageStatus from '@/enums/StorageStatus.js';
import { operationsColumns } from '@/modules/finance/tables/operationsTable.js';
import { useRouter } from 'vue-router';
import AppTable from '@/components/AppTable.vue';
import AppPagination from '@/components/AppPagination.vue';
import { useAccountsQuery } from '@/query/useAccountQuery.js';
import { formatMoney } from '@/use/useFormat.js';
import AppButton from '@/components/AppButton.vue';
import OperationCreateModal from '@/modules/finance/components/OperationCreateModal.vue';
import CabinetLayout from '@/layouts/CabinetLayout.vue';
import { tabs } from '@/modules/finance/finance-tabs.js';
import OperationsIndexFilters from '@/modules/finance/components/OperationsIndexFilters.vue';
import { useAuthStore } from '@/store/AuthStore';

const status = ref(StorageStatus.IN_STOCK);
const include = ['recipient', 'sender', 'category', 'employee'];
const currentPage = ref(1);
const perPage = 30;
const options = { keepPreviousData: true };
const { hasAccess } = useAuthStore();

const amountRangeStart = ref(null);
const amountRangeEnd = ref(null);
const dateRangeStart = ref(null);
const dateRangeEnd = ref(null);
const direction = ref([]);
const categoryId = ref([]);
const accountId = ref([]);

const { data, isLoading, isFetching } = useOperationsQuery(
  {
    status,
    perPage,
    page: currentPage,
    amountRangeStart,
    amountRangeEnd,
    dateRangeStart,
    dateRangeEnd,
    direction,
    categoryId,
    accountId,
    include,
  },
  options
);

const operations = computed(() => {
  return data?.value?.data;
});

const total = computed(() => {
  return data?.value?.meta?.total | 1;
});

const incomeTotal = computed(() => {
  return data?.value?.meta?.incomeTotal | 0;
});

const expenseTotal = computed(() => {
  return data?.value?.meta?.expenseTotal | 0;
});

const { data: accounts } = useAccountsQuery({ isArchived: false }, { select: (response) => response?.data?.data });

const router = useRouter();
const operationCreateModalShow = ref(false);
</script>

<template>
  <CabinetLayout :tabs="tabs" :loading="isLoading || isFetching" :hide-in-loading="isLoading === true">
    <OperationCreateModal v-if="operationCreateModalShow" @closed="operationCreateModalShow = false"
      @created="operationCreateModalShow = false" />

    <div class="flex flex-col gap-y-[18px]">
      <div class="flex flex-wrap gap-[18px] items-center justify-between">
        <div class="flex flex-wrap gap-[18px] items-center">
          <div class="text-lg font-medium">Баланс</div>
          <div class="flex flex-wrap gap-[12px]">
            <div v-for="account in accounts" :key="account.accountId"
              class="flex items-center gap-x-[6px] py-[12px] px-[16px] bg-[#FAFAFA] border border-[#EDECEC] rounded-[6px]">
              <div class="text-lg font-medium">{{ formatMoney(account.balance) }}</div>
              <div class="text-gray-primary">/ {{ account.title }}</div>
            </div>
          </div>
        </div>

        <AppButton v-if="hasAccess('create-Operation')" class="w-full md:w-auto"
          @click="operationCreateModalShow = true">Новая операция</AppButton>
      </div>

      <OperationsIndexFilters v-model:direction="direction" v-model:account-id="accountId"
        v-model:category-id="categoryId" v-model:amount-range-start="amountRangeStart"
        v-model:amount-range-end="amountRangeEnd" v-model:date-range-start="dateRangeStart"
        v-model:date-range-end="dateRangeEnd" />

      <AppTable sticky-headers class="text-sm" :data="operations" :columns="operationsColumns"
        row-class="cursor-pointer" @row-clicked="
          (operation) => router.push({ name: 'operation.show', params: { operationId: operation.operationId } })
        ">
      </AppTable>
    </div>

    <template #footer-sticky>
      <div class="py-[16px] md:py-0 md:h-[70px] grow flex flex-wrap gap-[12px] justify-between items-center">
        <div class="flex flex-wrap gap-[12px] font-medium">
          <div class="flex items-center gap-x-[6px]">
            <div>Приход</div>
            <div class="text-gray-primary">{{ formatMoney(incomeTotal) }}</div>
          </div>

          <div class="flex items-center gap-x-[6px]">
            <div>Расход</div>
            <div class="text-gray-primary">{{ formatMoney(expenseTotal) }}</div>
          </div>

          <div class="flex items-center gap-x-[6px]">
            <div>Сальдо</div>
            <div class="text-gray-primary">{{ formatMoney(incomeTotal - expenseTotal) }}</div>
          </div>
        </div>

        <AppPagination v-model="currentPage" :total="total" :per-page="perPage" />
      </div>
    </template>
  </CabinetLayout>
</template>
